import { VHOST } from "../constants/ovenmedia";
import { OvenMediaContext } from "../interfaces/context";

export const getNbOfStream = (contexts: OvenMediaContext[], serverId?: string): number => {
  const result: { vhost: string; app: string; stream: string }[] = [];
    const checkServer = (serverId: string) => {
      const context = contexts?.find((c) => c.server._id === serverId);
      if (!context) return;
      if (context?.vhosts[VHOST]?.apps) {
        const apps = Object.keys(context?.vhosts[VHOST]?.apps);
        apps.forEach((app) => {
          const curr = context?.vhosts[VHOST]?.apps[app]?.streams;
          const streams = Object.keys(curr);
          streams?.forEach((stream) => {
            if (!result.find((r) => r.vhost === VHOST && r.app === app && r.stream === stream)) {
              result.push({ vhost: VHOST, app, stream });
            }
          });
        });
      }
    };
    if (serverId) {
      checkServer(serverId);
      return result.length;
    }
    contexts?.forEach((c) => {
      checkServer(c.server._id);
    });
    return result.length;
}

export const getNbOfStreamForApp = (contexts: OvenMediaContext[], app: string, serverId?: string): number => {
  const result: { vhost: string; app: string; stream: string }[] = [];
    const checkServer = (serverId: string) => {
      const context = contexts?.find((c) => c.server._id === serverId);
      if (!context) return;
      if (context?.vhosts[VHOST]?.apps) {
        const curr = context?.vhosts[VHOST]?.apps[app]?.streams;
        const streams = Object.keys(curr);
        streams?.forEach((stream) => {
          if (!result.find((r) => r.vhost === VHOST && r.app === app && r.stream === stream)) {
            result.push({ vhost: VHOST, app, stream });
          }
        });
      }
    };
    if (serverId) {
      checkServer(serverId);
      return result.length;
    }
    contexts?.forEach((c) => {
      checkServer(c.server._id);
    });
    return result.length;
}