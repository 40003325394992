import { useParams } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import PageContainer from "../../components/page/PageContainer";
import { VHOST } from "../../constants/ovenmedia";
import {
  OvenMediaAppContext,
  OvenMediaAppOutputProfile,
  OvenMediaAppOutputProfileEncodeAudio,
  OvenMediaAppOutputProfileEncodeVideo,
  OvenMediaAppOutputProfilePlaylist,
  OvenMediaContext,
} from "../../interfaces/context";
import { useOvenMediaContexts } from "../../store/context/hooks";
import { useMultiServer } from "../../store/session/hooks";
import TableAudioEncoder from "./containers/table-audio-encoder";
import TablePlaylistRendition from "./containers/table-renditions-encoder";
import TableVideoEncoder from "./containers/table-video-encoder";
import styles from "./playlists.module.css";

const PlaylistsPage = () => {
  const { app } = useParams();
  const contexts = useOvenMediaContexts();
  const multiServer = useMultiServer();

  const renderContent = (context: OvenMediaContext) => {
    const appContext: OvenMediaAppContext | undefined = app ? context?.vhosts[VHOST]?.apps?.[app] : undefined;

    const profiles: OvenMediaAppOutputProfile[] = appContext?.outputProfiles ?? [];
    const playlists: OvenMediaAppOutputProfilePlaylist[] = [];
    const videoEncoders: OvenMediaAppOutputProfileEncodeVideo[] = [];
    const audioEncoders: OvenMediaAppOutputProfileEncodeAudio[] = [];

    if (profiles) {
      profiles.forEach((profile) => {
        if (profile.encodes?.videos) {
          videoEncoders.push(...profile.encodes.videos);
        }
        if (profile.encodes?.audios) {
          audioEncoders.push(...profile.encodes.audios);
        }
        if (profile.playlists) {
          playlists.push(...profile.playlists);
        }
      });
    }

    const renderPlaylist = (playlist: OvenMediaAppOutputProfilePlaylist) => {
      if (!playlist) return null;
      return (
        <div key={`key_playlist_${playlist.name}`} className={styles.playlistContainer}>
          <div className={styles.playlistTitle}>{playlist.name}</div>
          <div className={styles.playlistSeparator}></div>
          <div className={styles.playlistSubtitle}>{"Renditions" /* TRANSLATION */}</div>
          <TablePlaylistRendition data={playlist.renditions} />
        </div>
      );
    };

    return (
      <>
        <div className={styles.title}>{"Playlists" /* TRANSLATION */}</div>
        <br />
        <>{playlists.map((playlist) => renderPlaylist(playlist))}</>

        <br />

        <div className={styles.title}>{"Video encoders" /* TRANSLATION */}</div>
        <TableVideoEncoder data={videoEncoders} />

        <br />

        <div className={styles.title}>{"Audio encoders" /* TRANSLATION */}</div>
        <TableAudioEncoder data={audioEncoders} />

        <br />

        <div className={styles.title}>{"Providers (incoming)" /* TRANSLATION */}</div>
        <div className={styles.listContainer}>
          {appContext?.providers?.map((p) => {
            return (
              <div key={`provider_${p}`} className={styles.listItem}>
                {p}
              </div>
            );
          })}
        </div>

        <div className={styles.title}>{"Publishers (outgoing)" /* TRANSLATION */}</div>
        <div className={styles.listContainer}>
          {appContext?.publishers?.map((p) => {
            return (
              <div key={`publisher_${p}`} className={styles.listItem}>
                {p}
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <PageContainer title={"Playlists" /* TRANSLATION */} subtitle={app} loading={!contexts?.length}>
      {multiServer ? (
          <Tabs className={[styles.tabs].join(" ")}>
            <TabList>
              {contexts.map((c) => (
                <Tab key={`playlist_tab_${c.server._id}`}>{`Server ${c.server._id}`}</Tab>
              ))}
            </TabList>
            {contexts.map((c) => (
              <TabPanel className={[styles.tabsContent, "react-tabs-content"].join(" ")} key={`playlist_content_${c.server._id}`}>
                {renderContent(c)}
              </TabPanel>
            ))}
          </Tabs>
        ) : (
          renderContent(contexts[0])
        )}
    </PageContainer>
  );
};

export default PlaylistsPage;
