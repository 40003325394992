

export const getEnvApiUrl = () : string | undefined => {
  // see scripts/set-env.js & public/load_env.js
  let url;
  const customLocalData = (window as any).__custom_local_data__;
  if (customLocalData?.API_URL) {
    try {
      const urlObj = new URL(customLocalData.API_URL);
      url = urlObj.href;
    } catch {}
  }
  return url;
}

export const getEnvMultiServer = () : boolean | undefined => {
  // see scripts/set-env.js & public/load_env.js
  const customLocalData = (window as any).__custom_local_data__;
  if (customLocalData?.MULTI_SERVERS !== undefined) return customLocalData.MULTI_SERVERS === "true";
  return undefined;
}