import { Touchable } from "@kalyzee/kast-app-web-components";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import PageContainer from "../../components/page/PageContainer";
import { CustomCheckBox } from "../../components/utils/CustomCheckBox";
import Pagination from "../../components/utils/Pagination";
import SearchInput from "../../components/utils/SearchInput";
import Colors from "../../constants/colors";
import { VHOST } from "../../constants/ovenmedia";
import { OvenMediaSocketResultEmittedCommand } from "../../helpers/socket";
import { toastError } from "../../helpers/toast";
import { useSocketAppDispatch } from "../../hooks/app";
import { OvenMediaLiveSession } from "../../interfaces/context";
import { LiveSessionStatus, SocketGetSessionParams, socketGetLiveSessions } from "../../store/socket/actions";
import TableSession from "./containers/table-session";
import styles from "./history.module.css";
import { useMultiServer } from "../../store/session/hooks";
import { useOvenMediaContexts } from "../../store/context/hooks";

export const LIMIT = 15;

export interface OvenMediaGetSessionsResponse {
  limit: number;
  skip: number;
  count: number;
  total: number;
  sessions: OvenMediaLiveSession[];
}

const HistoriyPage = () => {
  const { app } = useParams();
  const [loading, setLoading] = useState(true);

  const socketDispatch = useSocketAppDispatch();
  const sessionsRef = useRef<OvenMediaLiveSession[]>([]);
  const searchRef = useRef<string>();
  const seachTimeoutRef = useRef<NodeJS.Timeout>();
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(LIMIT);
  const [sort, setSort] = useState<{ field: string, direction: -1 | 1}>({ field: 'startedAt', direction: -1});
  const [page, setPage] = useState<{ current: number; total: number }>({ current: 0, total: 1 });
  const [onlyWithRecords, setOnlyWithRecords] = useState(false);
  const [onlyWithRedirections, setOnlyWithRedirections] = useState(false);
  const contexts = useOvenMediaContexts();
  const multiServer = useMultiServer();

  const loadSessions = async (p?: Partial<SocketGetSessionParams>) => {
    const params: SocketGetSessionParams = {
      vhost: p?.vhost ?? VHOST,
      app: p?.app ?? app,
      skip: p?.skip ?? skip,
      limit: p?.limit ?? limit,
      sortField: p?.sortField ?? sort.field,
      sort: p?.sort ?? sort.direction,
      status: p?.status ?? [LiveSessionStatus.TERMINATED],
      search: p?.search ?? searchRef.current,
      onlyWithRecords: p?.onlyWithRecords ?? onlyWithRecords,
      onlyWithRedirections: p?.onlyWithRedirections ?? onlyWithRedirections,
    };
    if (!multiServer) params.serverId = contexts.length ? contexts[0].server._id : undefined;
    const result = await socketDispatch<OvenMediaSocketResultEmittedCommand<any, OvenMediaGetSessionsResponse>>(socketGetLiveSessions(params));
    if (!result) return;
    if (result.error) {
      toastError("Une erreur est survenue lors de la récupération des données ..." /* TRANSLATION */);
    } else if (result.response) {
      sessionsRef.current = result.response?.sessions ?? [];
      const skip = result.response.skip;
      const limit = result.response.limit;
      const total = result.response.total;
      setPage({
        current: Math.ceil(skip / limit),
        total: Math.ceil(total / limit),
      });
      setSkip(skip);
      setLimit(limit);
    }
    setLoading(false);
  };

  const reloadSessions = async (p?: Partial<SocketGetSessionParams>) => {
    setLoading(true);
    loadSessions(p);
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      loadSessions();
    }, 500);
  }, []);

  useEffect(() => {
    reloadSessions();
  }, [app, skip, sort, limit, onlyWithRecords, onlyWithRedirections]);

  return (
    <PageContainer title={"Historiques" /* TRANSLATION */} subtitle={app} loading={!contexts.length && loading}>
      <div style={{ width: "100%" }}>
        <div className={styles.searchRow}>
          <SearchInput
            key="searchinput"
            autoFocus
            defaultValue={searchRef.current}
            placeholder={"Search" /* TRANSLATE */}
            containerStyle={{ width: "200px", maxWidth: "90%" }}
            onChange={(event) => {
              const search = event.currentTarget.value;
              if (search === searchRef.current) return;
              searchRef.current = search;
              if (seachTimeoutRef.current) clearTimeout(seachTimeoutRef.current);
              seachTimeoutRef.current = setTimeout(() => {
                reloadSessions({ skip: 0 });
              }, 500);
            }}
          />
          <Touchable
            className={styles.refreshButton}
            onPress={async () => {
              reloadSessions();
            }}
          >
            {"Refresh" /* TRANSLATION */}
          </Touchable>
        </div>

        <div className={styles.selectorContainer}>
            <div className={styles.selectorRow}>
              <CustomCheckBox isSelected={onlyWithRecords} setSelected={(selected: boolean) => {
                setOnlyWithRecords(selected);
              }} />
              only with records
            </div>
            <div className={styles.selectorRow}>
              <CustomCheckBox isSelected={onlyWithRedirections} setSelected={(selected: boolean) => {
                setOnlyWithRedirections(selected);
              }} />
              only with redirections
            </div>
        </div>

        {sessionsRef.current?.length ? (
          <>
            <TableSession
              showApp={app === undefined}
              sort={sort}
              onSort={(field, direction: -1 | 1) => {
                if (sort.field !== field || sort.direction !== direction) {
                  setSort({
                    field,
                    direction,
                  });
                }
              }}
              data={sessionsRef.current}
            />
            <br />
            <Pagination
              textColor={Colors.getMainIndianKhaki()}
              textColorSelected={Colors.getMainWestCoast()}
              selected={page.current}
              count={page.total}
              onSelected={(selected: number) => {
                setSkip(selected * limit);
              }}
            />
          </>
        ) : (
          <div className={styles.noStream}>{"Aucun stream de trouvé ..." /* TRANSLATION */}</div>
        )}
      </div>
    </PageContainer>
  );
};

export default HistoriyPage;
