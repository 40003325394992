import PageContainer from "../../components/page/PageContainer";
import TableUploadProfile from "../../components/tables/table-upload-profiles";
import { useOvenMediaUploadProfiles } from "../../store/context/hooks";
import styles from "./upload-profiles.module.css";

const UploadProfilesPage = () => {
  const uploadProfiles = useOvenMediaUploadProfiles();
  return (
    <PageContainer title={"Upload profiles" /* TRANSLATION */}>
      <div style={{ width: "100%" }}>
        {uploadProfiles.length ? (
          <TableUploadProfile data={uploadProfiles} />
        ) : (
          <div className={styles.noStream}>{"Aucun upload profile n'a été trouvé..." /* TRANSLATION */}</div>
        )}
      </div>
    </PageContainer>
  );
};

export default UploadProfilesPage;
