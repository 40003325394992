import { Touchable } from "@kalyzee/kast-app-web-components";
import { Player } from "@kalyzee/kast-react-player-module";
import { OvenMediaReceiverWebRTCSession } from "@kalyzee/kast-webrtc-client-module";
import { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { ReactComponent as IconPlay } from "../../assets/icons/play.svg";
import PageContainer from "../../components/page/PageContainer";
import RtcAudioStats, { RtcAudioStatsRef } from "../../components/rtc/rtc-audio-stats";
import RtcVideoStats, { RtcVideoStatsRef } from "../../components/rtc/rtc-video-stats";
import { VHOST } from "../../constants/ovenmedia";
import { getOvenMediaHlsUrl, getOvenMediaWebsocketUrl } from "../../helpers/request";
import { addToPathNameUrl } from "../../helpers/utils";
import { OvenMediaAppContext, OvenMediaAppOutputProfile, OvenMediaAppOutputProfilePlaylist, OvenMediaContext } from "../../interfaces/context";
import { useOvenMediaContexts } from "../../store/context/hooks";
import { useMultiServer } from "../../store/session/hooks";
import styles from "./player.module.css";

const generateSrc = (type: "webrtc" | "llhls", context: OvenMediaContext, app?: string, stream?: string, playlist?: string): string | undefined => {
  if (type === "webrtc") {
    return addToPathNameUrl(getOvenMediaWebsocketUrl(context), `/${app}/${stream}/${playlist ? playlist : ""}`);
  }
  if (type === "llhls") {
    return addToPathNameUrl(getOvenMediaHlsUrl(context), `/${app}/${stream}/llhls.m3u8`);
  }
  return undefined;
};

const PlayerPage = () => {
  const { app, stream } = useParams();
  const [searchParams] = useSearchParams();
  const contexts = useOvenMediaContexts();
  const [type, setType] = useState<"webrtc" | "llhls">("webrtc");
  const [playlist, setPlaylist] = useState<string | undefined>("");
  const [media, setMedia] = useState<MediaStream | undefined>(undefined);
  const webrtcSession = useRef<OvenMediaReceiverWebRTCSession | undefined>(undefined);
  const videoStatsRef = useRef<RtcVideoStatsRef>();
  const audioStatsRef = useRef<RtcAudioStatsRef>();
  const [currContext, setCurrContext] = useState<OvenMediaContext | undefined>();
  const multiServer = useMultiServer();

  const fullscreen = (searchParams.get("fullscreen") ?? "") === "true";

  useEffect(() => {
    const serverId = searchParams.get("serverId") ?? undefined;
    if (serverId) {
      const context = contexts.find((c) => c.server._id === serverId);
      if (context) setCurrContext(context);
    }
    if (!multiServer) setCurrContext(contexts[0]);
  }, [contexts]);

  useEffect(() => {
    if (type === "llhls") return;
    if (!currContext) return;
    const src = generateSrc("webrtc", currContext, app, stream, playlist);
    if (!src) return;
    const session = new OvenMediaReceiverWebRTCSession();
    session.start(src);
    session.addEventListener("stream", (stream) => {
      setMedia(stream);
    });
    session.addEventListener("stats", (connection, track, stats) => {
      if (track?.kind === "video") {
        videoStatsRef.current?.update(stats);
      }
      if (track?.kind === "audio") {
        audioStatsRef.current?.update(stats);
      }
    });
    webrtcSession.current = session;
    return () => {
      session.destroy();
    };
  }, [currContext, app, stream, playlist, type]);

  const renderContent = (context: OvenMediaContext) => {
    const appContext: OvenMediaAppContext | undefined = app ? context?.vhosts[VHOST]?.apps?.[app] : undefined;
    const profiles: OvenMediaAppOutputProfile[] = appContext?.outputProfiles ?? [];
    const playlists: OvenMediaAppOutputProfilePlaylist[] = [];

    if (profiles) {
      profiles.forEach((profile) => {
        if (profile.playlists) {
          playlists.push(...profile.playlists);
        }
      });
    }

    return (
      <>
        <div className={styles.descriptionRow}>
          <div className={styles.descriptionTitle}>{"Application : " /* TRANSLATION */}</div>
          <div className={styles.descriptionValue}>{app}</div>
        </div>
        <div className={styles.descriptionRow}>
          <div className={styles.descriptionTitle}>{"Stream : " /* TRANSLATION */}</div>
          <div className={styles.descriptionValue}>{stream}</div>
        </div>
        <div className={styles.descriptionRow}>
          <div className={styles.descriptionTitle}>{"Type : " /* TRANSLATION */}</div>
          <div className={styles.descriptionValue}>{type}</div>
        </div>
        <div className={styles.descriptionRow}>
          <div className={styles.descriptionTitle}>{"Playlist : " /* TRANSLATION */}</div>
          <div className={styles.descriptionValue}>{playlist}</div>
        </div>
        <div className={styles.sectionTitle}>{"Playlists:" /* TRANSLATION */}</div>
        <div className={styles.playlistsContainer}>
          <div className={styles.playlistsTitle}>Webrtc: </div>
          <div className={styles.playlists}>
            <Touchable
              className={styles.playlistButton + (type === "webrtc" && playlist === undefined ? ` ${styles.playlistButtonSelected}` : "")}
              onPress={() => {
                setType("webrtc");
                setPlaylist(undefined);
              }}
            >
              <IconPlay width={20} height={20} />
              <div>Défaut</div>
            </Touchable>
            {playlists.map((p, index) => (
              <Touchable
                key={p.name}
                className={styles.playlistButton + (type === "webrtc" && playlist === p.name ? ` ${styles.playlistButtonSelected}` : "")}
                onPress={() => {
                  setType("webrtc");
                  setPlaylist(p.name);
                }}
              >
                <IconPlay width={20} height={20} />
                <div>{p.name}</div>
              </Touchable>
            ))}
          </div>
        </div>
        <div className={styles.playlistsContainer} style={{ marginTop: "10px" }}>
          <div className={styles.playlistsTitle}>LLHls: </div>
          <div className={styles.playlists}>
            <Touchable
              className={styles.playlistButton + (type === "llhls" && playlist === undefined ? ` ${styles.playlistButtonSelected}` : "")}
              onPress={() => {
                setType("llhls");
                setPlaylist(undefined);
              }}
            >
              <IconPlay width={20} height={20} />
              <div>Défaut</div>
            </Touchable>
          </div>
        </div>
        <div className={styles.sectionTitle}>{"Player:" /* TRANSLATION */}</div>
        <div className={styles.playerContainer}>
          {type === "webrtc" ? (
            <Player enableVideoControls media={media} className={`${styles.player}${fullscreen ? ` ${styles.fullscreenPlayer}` : ""}`} />
          ) : (
            <Player
              enableVideoControls
              src={generateSrc("llhls", context, app, stream, playlist)}
              srcIsHls
              className={`${styles.player}${fullscreen ? ` ${styles.fullscreenPlayer}` : ""}`}
            />
          )}
        </div>
        {type === "webrtc" ? (
          <div className={styles.sectionTitle}>
            {"Stats:" /* TRANSLATION */}
            <div className={styles.statsContainer}>
              <RtcVideoStats ref={videoStatsRef} />
              <RtcAudioStats ref={audioStatsRef} />
            </div>
          </div>
        ) : null}
      </>
    );
  };

  return (
    <PageContainer title={"Player" /* TRANSLATION */} subtitle={app} loading={false}>
      <div style={{ width: "100%" }}>
        {multiServer ? (
          <Tabs
            onSelect={(index) => {
              const context = index < contexts.length ? contexts[index] : undefined;
              setCurrContext(context);
            }}
          >
            <TabList>
              {contexts.map((c) => (
                <Tab key={`player_tab_${c.server._id}`}>{`Server ${c.server._id}`}</Tab>
              ))}
            </TabList>
            {contexts.map((c) => (
              <TabPanel key={`player_content_${c.server._id}`}>{renderContent(c)}</TabPanel>
            ))}
          </Tabs>
        ) : (
          renderContent(contexts[0])
        )}
      </div>
    </PageContainer>
  );
};

export default PlayerPage;
