import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, Outlet } from 'react-router-dom';
import { Loading } from '../utils/Loading';
import { AuthStatus } from '../../interfaces/session';

interface LoginRouteProps {
  redirectPath: string,
  authStatus: AuthStatus,
}

const LoginRoute = ({ redirectPath = '/', authStatus }: LoginRouteProps) => {
  if (authStatus === AuthStatus.In) {
    const url = new URL(window.location.href);
    const redirect = url.searchParams.get('redirect') || redirectPath;
    return <Navigate to={redirect} />;
  }
  if (authStatus === AuthStatus.Loading) {
    return <Loading />;
  }
  return <Outlet />;
};

export default LoginRoute;
