import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import IconApplications from '../../assets/icons/applications.svg';
import IconDashboard from '../../assets/icons/dashboard.svg';
import IconHistory from '../../assets/icons/history.svg';
import IconVods from '../../assets/icons/vods.svg';
import IconUploadProfiles from '../../assets/icons/upload-profile.svg';
import IconLogs from '../../assets/icons/logs.svg';
import IconPlaylist from '../../assets/icons/playlists.svg';
import IconServers from '../../assets/icons/servers.svg';
import { VHOST } from '../../constants/ovenmedia';
import { useOvenMediaContexts } from '../../store/context/hooks';
import { MenuDisplayMode, MenuItemDisplayMode } from './menu.constant';
import MenuItem, { MenuItemRef } from './MenuItem';
import styles from './MenuItems.module.css';
import MenuSubitem from './MenuSubitem';
import { getNbOfStream, getNbOfStreamForApp } from '../../helpers/streams';
import { useMultiServer } from '../../store/session/hooks';




const DEFAULT_DISPLAY_MODE = MenuDisplayMode.DEFAULT;

interface MenuItemData {
  ref?: MenuItemRef,
}
type MenuData = {
  [key in MenuItemName]: MenuItemData
};

export enum MenuItemName {
  DASHBOARD = 'Dashboard',
  APPLICATIONS = 'Applications',
  HISTORY = 'History',
  RECORDS = 'Records',
  UPLOAD_PROFILES = 'Upload profiles',
  PLAYLISTS = 'playlists',
  LOGS = 'Logs',
  SERVERS = 'servers',
}

const DEFAULT_MENU_DATA: { [key in MenuItemName]: MenuItemData } = {
  [MenuItemName.DASHBOARD]: {},
  [MenuItemName.APPLICATIONS]: {},
  [MenuItemName.HISTORY]: {},
  [MenuItemName.RECORDS]: {},
  [MenuItemName.UPLOAD_PROFILES]: {},
  [MenuItemName.PLAYLISTS]: {},
  [MenuItemName.SERVERS]: {},
  [MenuItemName.LOGS]: {},
};

const LOCATIONS = {
  [MenuItemName.DASHBOARD]: '/dashboard',
  [MenuItemName.APPLICATIONS]: '/application',
  [MenuItemName.HISTORY]: '/history',
  [MenuItemName.RECORDS]: '/records',
  [MenuItemName.UPLOAD_PROFILES]: '/upload-profiles',
  [MenuItemName.PLAYLISTS]: '/playlists',
  [MenuItemName.SERVERS]: '/servers',
  [MenuItemName.LOGS]: '/logs',
};

const getAllLocations = (item: MenuItemName): string[] => {
  const locations: string[] = [];
  const obj: string | string[] = LOCATIONS[item];
  if (typeof obj === 'string') locations.push(obj);
  /*else {
    locations.push(...Object.values(obj));
  }*/
  return locations;
};

const MATCH_LOCATIONS: { [key in MenuItemName]: (string | RegExp)[] } = {
  [MenuItemName.DASHBOARD]: ['/', ...getAllLocations(MenuItemName.DASHBOARD)], // "/" or "/applications"
  [MenuItemName.APPLICATIONS]: getAllLocations(MenuItemName.APPLICATIONS),
  [MenuItemName.HISTORY]: getAllLocations(MenuItemName.HISTORY),
  [MenuItemName.RECORDS]: getAllLocations(MenuItemName.RECORDS),
  [MenuItemName.UPLOAD_PROFILES]: getAllLocations(MenuItemName.UPLOAD_PROFILES),
  [MenuItemName.PLAYLISTS]: getAllLocations(MenuItemName.PLAYLISTS),
  [MenuItemName.SERVERS]: getAllLocations(MenuItemName.SERVERS),
  [MenuItemName.LOGS]: getAllLocations(MenuItemName.LOGS),
};

export interface MenuItemsProps {
  onItemSelected?: (value: boolean, ref: MenuItemRef) => void;
  onItemOpened?: (value: boolean, ref: MenuItemRef) => void;
  onNavigateTo?: (itemName: MenuItemName, path: string) => void;
  displayMode?: MenuDisplayMode;
  className?: string;
  style?: React.CSSProperties;
}
export interface MenuItemsRef {
}

const MenuItems = React.forwardRef((
  {
    onItemSelected,
    onItemOpened,
    onNavigateTo,
    displayMode = DEFAULT_DISPLAY_MODE,
    className,
    style,
  }: MenuItemsProps,
  forwardRef: React.ForwardedRef<MenuItemsRef | undefined>,
) => {
  const [mode, setMode] = useState<MenuDisplayMode>(DEFAULT_DISPLAY_MODE);
  const itemRefs = useRef<MenuData>(DEFAULT_MENU_DATA);
  const multiServer = useMultiServer();

  const contexts = useOvenMediaContexts();
  const applications = useMemo(() => {
    const applications = new Set<string>();
    contexts?.forEach((context) => {
      if (!context) return;
      if (!context.vhosts[VHOST]) return;
      const apps = Object.keys(context.vhosts[VHOST].apps).sort((a, b) => a.localeCompare(b));
      apps.forEach((a) => applications.add(a));
    });
    return Array.from(applications);
  }, [contexts]);


  useImperativeHandle(forwardRef, () => ({}));

  const openItem = (value: boolean, ref?: MenuItemRef) => {
    ref?.open(value);
  };

  useEffect(() => {
    setMode(displayMode ?? DEFAULT_DISPLAY_MODE);
  }, [displayMode]);

  useEffect(() => {
    if (mode === MenuDisplayMode.SMALL) {
      const menuData: MenuData = itemRefs.current;
      const currentItems: MenuItemData[] = Object.values(menuData);
      currentItems.forEach((item) => {
        openItem(false, item.ref);
        if (item.ref) item.ref.select(item.ref?.isMatching());
      });
    }
  }, [mode]);

  const itemOnSelected = (menuItemName: MenuItemName) => (selected: boolean, ref: MenuItemRef) => {
    const menuData: MenuData = itemRefs.current;
    const currentItemNames: MenuItemName[] = Object.keys(menuData) as MenuItemName[];
    const currentItem: MenuItemData | undefined = menuData[menuItemName];
    if (!currentItem) return;
    currentItemNames.forEach((name) => {
      const item = menuData[name];
      const currentRef = item.ref;
      if (selected) {
        if (currentRef) {
          if (menuItemName !== name) {
            currentRef.select(false);
            openItem(false, currentRef);
          }
        }
      }
    });
    onItemSelected?.(selected, ref);
  };

  const itemOnMatching = (menuItemName: MenuItemName) => (matching: boolean, ref: MenuItemRef) => {
    if (!matching) {
      openItem(false, ref);
    }
  };

  const itemOnOpened = (menuItemName: MenuItemName) => (opened: boolean, ref: MenuItemRef) => {
    onItemOpened?.(opened, ref);
  };

  const itemHovered = (menuItemName: MenuItemName) => (hover: boolean, ref: MenuItemRef) => {
    const isOpened = ref?.isOpened();
    if (mode === MenuDisplayMode.SMALL) {
      if (hover && !isOpened) {
        openItem(true, ref);
      } else if (!hover && isOpened && !ref.childIsHovered()) {
        openItem(false, ref);
      }
    }
  };

  const itemOnNavigateTo = (itemName: MenuItemName, path: string) => {
    onNavigateTo?.(itemName, path);
  };

  const subItemOnNavigateTo = (itemName: MenuItemName, path: string) => {
    const data = itemRefs.current[itemName];
    if (mode === MenuDisplayMode.SMALL) {
      data?.ref?.open(false);
    }
    data?.ref?.select(true);
    onNavigateTo?.(itemName, path);
  };

  const getItemDisplayMode = () => {
    if (mode === MenuDisplayMode.SMALL) return MenuItemDisplayMode.ICON_ONLY;
    return MenuItemDisplayMode.DEFAULT;
  };

  const classes = [styles.container];
  if (className) classes.push(className);
  classes.push(mode === MenuDisplayMode.SMALL
    ? styles.displayModeSmall : styles.displayModeDefault);

  const streams = getNbOfStream(contexts);
  return (
    <div
      className={classes.join(' ')}
      style={style}
    >
      <MenuItem
        className={styles.item}
        iconSrc={IconDashboard}
        matchLocations={MATCH_LOCATIONS[MenuItemName.DASHBOARD]}
        navigateTo={LOCATIONS[MenuItemName.DASHBOARD]}
        title={'Dashboard'/* TRANSLATION */}
        ref={(forwardedRef: MenuItemRef) => {
          itemRefs.current[MenuItemName.DASHBOARD].ref = forwardedRef;
        }}
        displayMode={getItemDisplayMode()}
        onSelected={itemOnSelected(MenuItemName.DASHBOARD)}
        onMatching={itemOnMatching(MenuItemName.DASHBOARD)}
        onOpened={itemOnOpened(MenuItemName.DASHBOARD)}
        onHovered={itemHovered(MenuItemName.DASHBOARD)}
        onNavigateTo={(path) => itemOnNavigateTo(MenuItemName.DASHBOARD, path)}
      >
      </MenuItem>

      <MenuItem
        className={styles.item}
        iconSrc={IconApplications}
        matchLocations={MATCH_LOCATIONS[MenuItemName.APPLICATIONS]}
        title={(
          <>
            <span>{'Applications'/* TRANSLATION */}</span><span className={streams ? styles.streamBadgeEnabled : styles.streamBadgeDisabled}>{`${streams}`}</span>
          </>
        )}
        ref={(forwardedRef: MenuItemRef) => {
          itemRefs.current[MenuItemName.APPLICATIONS].ref = forwardedRef;
        }}
        displayMode={getItemDisplayMode()}
        onSelected={itemOnSelected(MenuItemName.APPLICATIONS)}
        onMatching={itemOnMatching(MenuItemName.APPLICATIONS)}
        onOpened={itemOnOpened(MenuItemName.APPLICATIONS)}
        onHovered={itemHovered(MenuItemName.APPLICATIONS)}
        onNavigateTo={(path) => itemOnNavigateTo(MenuItemName.APPLICATIONS, path)}
      >
        <MenuSubitem
          title={(
            <>
              <span className={styles.seeAll}>{'Tout voir' /* Translation */}</span>
              {streams ? <span className={styles.streamBadgeEnabled}>{`${streams}`}</span> : null}
            </>
          )}
          navigateTo={`${LOCATIONS[MenuItemName.APPLICATIONS]}/`}
          onNavigateTo={(path) => subItemOnNavigateTo(MenuItemName.APPLICATIONS, path)}
        />
        {
          applications.map((application) => {
            const streams = getNbOfStreamForApp(contexts, application);
            return (
              <div key={application}>
                <MenuSubitem
                  title={(
                    <>
                      <span>{application}</span>
                      {streams ? <span className={styles.streamBadgeEnabled}>{`${streams}`}</span> : null}
                    </>
                  )}
                  navigateTo={`${LOCATIONS[MenuItemName.APPLICATIONS]}/${application}`}
                  onNavigateTo={(path) => subItemOnNavigateTo(MenuItemName.APPLICATIONS, path)}
                />
              </div>
            );
          })
        }
      </MenuItem>

      <MenuItem
        className={styles.item}
        iconSrc={IconHistory}
        matchLocations={MATCH_LOCATIONS[MenuItemName.HISTORY]}
        title={'Historiques'/* TRANSLATION */}
        ref={(forwardedRef: MenuItemRef) => {
          itemRefs.current[MenuItemName.HISTORY].ref = forwardedRef;
        }}
        displayMode={getItemDisplayMode()}
        onSelected={itemOnSelected(MenuItemName.HISTORY)}
        onMatching={itemOnMatching(MenuItemName.HISTORY)}
        onOpened={itemOnOpened(MenuItemName.HISTORY)}
        onHovered={itemHovered(MenuItemName.HISTORY)}
        onNavigateTo={(path) => itemOnNavigateTo(MenuItemName.HISTORY, path)}
      >
        <MenuSubitem
          title={(
            <>
              <span className={styles.seeAll}>{'Tout voir' /* Translation */}</span>
            </>
          )}
          navigateTo={`${LOCATIONS[MenuItemName.HISTORY]}/`}
          onNavigateTo={(path) => subItemOnNavigateTo(MenuItemName.HISTORY, path)}
        />
        {
          applications.map((application) => (
            <div key={application}>
              <MenuSubitem
                title={application}
                navigateTo={`${LOCATIONS[MenuItemName.HISTORY]}/${application}`}
                onNavigateTo={(path) => subItemOnNavigateTo(MenuItemName.HISTORY, path)}
              />
            </div>
          ))
        }
      </MenuItem>

      <MenuItem
        className={styles.item}
        iconSrc={IconVods}
        matchLocations={MATCH_LOCATIONS[MenuItemName.RECORDS]}
        title={'Records'/* TRANSLATION */}
        ref={(forwardedRef: MenuItemRef) => {
          itemRefs.current[MenuItemName.RECORDS].ref = forwardedRef;
        }}
        displayMode={getItemDisplayMode()}
        onSelected={itemOnSelected(MenuItemName.RECORDS)}
        onMatching={itemOnMatching(MenuItemName.RECORDS)}
        onOpened={itemOnOpened(MenuItemName.RECORDS)}
        onHovered={itemHovered(MenuItemName.RECORDS)}
        onNavigateTo={(path) => itemOnNavigateTo(MenuItemName.RECORDS, path)}
      >
        <MenuSubitem
          title={(
            <>
              <span className={styles.seeAll}>{'Tout voir' /* Translation */}</span>
            </>
          )}
          navigateTo={`${LOCATIONS[MenuItemName.RECORDS]}/`}
          onNavigateTo={(path) => subItemOnNavigateTo(MenuItemName.RECORDS, path)}
        />
        {
          applications.map((application) => (
            <div key={application}>
              <MenuSubitem
                title={application}
                navigateTo={`${LOCATIONS[MenuItemName.RECORDS]}/${application}`}
                onNavigateTo={(path) => subItemOnNavigateTo(MenuItemName.RECORDS, path)}
              />
            </div>
          ))
        }
      </MenuItem>

      <MenuItem
        className={styles.item}
        iconSrc={IconUploadProfiles}
        matchLocations={MATCH_LOCATIONS[MenuItemName.UPLOAD_PROFILES]}
        navigateTo={LOCATIONS[MenuItemName.UPLOAD_PROFILES]}
        title={'Upload profiles'/* TRANSLATION */}
        ref={(forwardedRef: MenuItemRef) => {
          itemRefs.current[MenuItemName.UPLOAD_PROFILES].ref = forwardedRef;
        }}
        displayMode={getItemDisplayMode()}
        onSelected={itemOnSelected(MenuItemName.UPLOAD_PROFILES)}
        onMatching={itemOnMatching(MenuItemName.UPLOAD_PROFILES)}
        onOpened={itemOnOpened(MenuItemName.UPLOAD_PROFILES)}
        onHovered={itemHovered(MenuItemName.UPLOAD_PROFILES)}
        onNavigateTo={(path) => itemOnNavigateTo(MenuItemName.UPLOAD_PROFILES, path)}
      >
      </MenuItem>

      <MenuItem
        className={styles.item}
        iconSrc={IconPlaylist}
        matchLocations={MATCH_LOCATIONS[MenuItemName.PLAYLISTS]}
        title={'Playlists'/* TRANSLATION */}
        ref={(forwardedRef: MenuItemRef) => {
          itemRefs.current[MenuItemName.PLAYLISTS].ref = forwardedRef;
        }}
        displayMode={getItemDisplayMode()}
        onSelected={itemOnSelected(MenuItemName.PLAYLISTS)}
        onMatching={itemOnMatching(MenuItemName.PLAYLISTS)}
        onOpened={itemOnOpened(MenuItemName.PLAYLISTS)}
        onHovered={itemHovered(MenuItemName.PLAYLISTS)}
        onNavigateTo={(path) => itemOnNavigateTo(MenuItemName.PLAYLISTS, path)}
      >
        {
          applications.map((application) => (
            <div key={application}>
              <MenuSubitem
                title={application}
                navigateTo={`${LOCATIONS[MenuItemName.PLAYLISTS]}/${application}`}
                onNavigateTo={(path) => subItemOnNavigateTo(MenuItemName.PLAYLISTS, path)}
              />
            </div>
          ))
        }
      </MenuItem>

      <MenuItem
        className={styles.item}
        iconSrc={IconServers}
        matchLocations={MATCH_LOCATIONS[MenuItemName.SERVERS]}
        navigateTo={LOCATIONS[MenuItemName.SERVERS]}
        title={multiServer ? 'Servers' : 'Server'}
        ref={(forwardedRef: MenuItemRef) => {
          itemRefs.current[MenuItemName.SERVERS].ref = forwardedRef;
        }}
        displayMode={getItemDisplayMode()}
        onSelected={itemOnSelected(MenuItemName.SERVERS)}
        onMatching={itemOnMatching(MenuItemName.SERVERS)}
        onOpened={itemOnOpened(MenuItemName.SERVERS)}
        onHovered={itemHovered(MenuItemName.SERVERS)}
        onNavigateTo={(path) => itemOnNavigateTo(MenuItemName.SERVERS, path)}
      >
      </MenuItem>

      <MenuItem
        className={styles.item}
        iconSrc={IconLogs}
        matchLocations={MATCH_LOCATIONS[MenuItemName.LOGS]}
        navigateTo={LOCATIONS[MenuItemName.LOGS]}
        title={'Logs'/* TRANSLATION */}
        ref={(forwardedRef: MenuItemRef) => {
          itemRefs.current[MenuItemName.LOGS].ref = forwardedRef;
        }}
        displayMode={getItemDisplayMode()}
        onSelected={itemOnSelected(MenuItemName.LOGS)}
        onMatching={itemOnMatching(MenuItemName.LOGS)}
        onOpened={itemOnOpened(MenuItemName.LOGS)}
        onHovered={itemHovered(MenuItemName.LOGS)}
        onNavigateTo={(path) => itemOnNavigateTo(MenuItemName.LOGS, path)}
      >
      </MenuItem>
    </div>
  );
});

export default MenuItems;
