import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OvenMediaContext } from '../../interfaces/context';
import { OvenMediaStats } from '../../interfaces/stats';

export interface ContextState {
  contexts: {[serverId: string]: OvenMediaContext},
  stats: {[serverId: string]: OvenMediaStats},
}

const initialState: ContextState = {
  contexts: {},
  stats: {}
};

export const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    setContext: (state, action: PayloadAction<{serverId: string, context: OvenMediaContext}>) => {
      const { serverId , context } = action.payload;
      state.contexts[serverId] = context;
    },
    setStats: (state, action: PayloadAction<{serverId: string, stats: OvenMediaStats}>) => {
      const { serverId , stats } = action.payload;
      state.stats[serverId] = stats;
    },
  },
});

export const {
  setContext,
  setStats,
} = socketSlice.actions;

export default socketSlice.reducer;
