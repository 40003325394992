import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import PageContainer from "../../components/page/PageContainer";
import { VHOST } from "../../constants/ovenmedia";
import { OvenMediaAppContext, OvenMediaAppProvider, OvenMediaAppPublisher, OvenMediaStreamContext } from "../../interfaces/context";
import { useOvenMediaAllStats, useOvenMediaContexts } from "../../store/context/hooks";
import TableApplication, { TableApplicationData } from "./container/table-application";

import styles from "./application.module.css";
import ApplicationNetworkContainer from "./container/network";
import { useMultiServer } from "../../store/session/hooks";
import { Touchable } from "@kalyzee/kast-app-web-components";

const ApplicationPage = () => {
  const { app } = useParams();
  const allStats = useOvenMediaAllStats();
  const contexts = useOvenMediaContexts();
  const multiServer = useMultiServer();
  const [showNetworkDetails, setShowNetworkDetails] = useState(false);

  const { data, providers, publishers } = useMemo(() => {
    let data: TableApplicationData[] = [];
    const publishers = new Set<OvenMediaAppPublisher>();
    const providers = new Set<OvenMediaAppProvider>();

    const ovtsAssociated: TableApplicationData["ovts"] = [];

    contexts?.forEach((context) => {
      let appContext: OvenMediaAppContext | undefined = app ? context?.vhosts[VHOST]?.apps?.[app] : undefined;
      let stats = allStats?.[context.server._id];

      if (app) {
        const currAppContext = appContext;
        const currAppStats = app ? stats?.vhosts[VHOST]?.apps?.[app] : undefined;
        if (currAppContext) {
          const streams = Object.keys(currAppContext?.streams);
          streams.forEach((stream) => {
            const streamContext: OvenMediaStreamContext = currAppContext.streams?.[stream];
            if (streamContext) {
              const ovts: TableApplicationData["ovts"] = [];
              if (streamContext.live && streamContext.input?.sourceType?.toLocaleLowerCase() !== "ovt") {
                contexts.forEach((c) => {
                  const currStreamContext = c.vhosts[VHOST]?.apps?.[app]?.streams[stream];
                  if (currStreamContext && currStreamContext.input?.sourceType.toLocaleLowerCase() === "ovt") {
                    const ovt = {
                      context: c,
                      app: c.vhosts[VHOST].apps[app],
                      stream: currStreamContext,
                      stats: allStats?.[c.server._id]?.vhosts[VHOST]?.apps?.[app]?.streams[stream],
                    };
                    ovtsAssociated.push(ovt);
                    ovts.push(ovt);
                  }
                });
              }
              data.push({
                context,
                app: currAppContext,
                stream: streamContext,
                stats: currAppStats?.streams[stream],
                ovts: ovts,
              });
            }
          });
        }
        context?.vhosts[VHOST]?.apps?.[app].providers?.forEach((p) => providers.add(p));
        context?.vhosts[VHOST]?.apps?.[app].publishers?.forEach((p) => publishers.add(p));
      } else if (context?.vhosts[VHOST]?.apps) {
        const apps = Object.keys(context?.vhosts[VHOST]?.apps);
        apps.forEach((app) => {
          const appContext: OvenMediaAppContext | undefined = app ? context?.vhosts[VHOST]?.apps?.[app] : undefined;
          const currAppStats = app ? stats?.vhosts[VHOST]?.apps?.[app] : undefined;
          if (appContext) {
            const streams = Object.keys(appContext?.streams);
            streams.forEach((stream) => {
              const streamContext: OvenMediaStreamContext = appContext.streams?.[stream];
              if (streamContext) {
                const ovts: TableApplicationData["ovts"] = [];
                if (streamContext.live && streamContext.input?.sourceType.toLocaleLowerCase() !== "ovt") {
                  contexts.forEach((c) => {
                    const currStreamContext = c.vhosts[VHOST]?.apps?.[app]?.streams[stream];
                    if (currStreamContext && currStreamContext.input?.sourceType.toLocaleLowerCase() === "ovt") {
                      const ovt = {
                        context: c,
                        app: c.vhosts[VHOST].apps[app],
                        stream: currStreamContext,
                        stats: allStats?.[c.server._id]?.vhosts[VHOST]?.apps?.[app]?.streams[stream],
                      };
                      ovtsAssociated.push(ovt);
                      ovts.push(ovt);
                    }
                  });
                }
                data.push({
                  context,
                  app: appContext,
                  stream: streamContext,
                  stats: currAppStats?.streams[stream],
                  ovts: ovts,
                });
              }
            });
          }
        });
      }
    });

    data = data.filter((d) => {
      return !ovtsAssociated.find((o) => o.context === d.context && o.app === d.app && o.stream === d.stream);
    });

    return { data, providers: Array.from(providers), publishers: Array.from(publishers) };
  }, [contexts, allStats]);

  return (
    <PageContainer title={"Application" /* TRANSLATION */} subtitle={app} loading={!contexts?.length}>
      <div style={{ width: "100%" }}>
        {data?.length ? (
          <>
            <div className={styles.sectionTitle}>{"ACTIVE LIVE STREAMS" /* TRANSLATION */}</div>
            <TableApplication showApp={app === undefined} showServer={true} data={data} />
          </>
        ) : (
          <div className={styles.noStream}>{"Aucun stream en cours ..." /* TRANSLATION */}</div>
        )}
        <br />
        {app ? (
          <>
            <ApplicationNetworkContainer vhost={VHOST} app={app} />
            {multiServer ? (
              <div className={styles.detailsContainer}>
                <Touchable className={styles.detailsSeparator} onPress={() => setShowNetworkDetails(!showNetworkDetails)}>
                  <div style={{ height: "1px", flex: 1, backgroundColor: "var(--color-main-indian-khaki)" }} />
                  <div className={styles.detailsSeparatorTitle}>{showNetworkDetails ? `Hide details ${"▲"}` : `Show details ${"▼"}`}</div>
                  <div style={{ height: "1px", flex: 1, backgroundColor: "var(--color-main-indian-khaki)" }} />
                </Touchable>
                {showNetworkDetails
                  ? contexts.map((c) => {
                      const serverId = c.server._id;
                      if (!serverId) return null;
                      return (
                        <div key={`application_network_details_${serverId}`}>
                          <div className={styles.detailsServer}>{`Server: ${serverId}`}</div>
                          <ApplicationNetworkContainer vhost={VHOST} app={app} serverId={serverId} />
                        </div>
                      );
                    })
                  : null}
              </div>
            ) : null}
            <br />
          </>
        ) : null}

        {providers.length ? (
          <>
            <div className={styles.sectionTitle}>{"Providers (incoming)" /* TRANSLATION */}</div>
            <div className={styles.listContainer}>
              {providers.map((p) => {
                return (
                  <div key={`provider_${p}`} className={styles.listItem}>
                    {p}
                  </div>
                );
              })}
            </div>
          </>
        ) : null}

        {publishers.length ? (
          <>
            <div className={styles.sectionTitle}>{"Publishers (outgoing)" /* TRANSLATION */}</div>
            <div className={styles.listContainer}>
              {publishers.map((p) => {
                return (
                  <div key={`publisher_${p}`} className={styles.listItem}>
                    {p}
                  </div>
                );
              })}
            </div>
          </>
        ) : null}
      </div>
    </PageContainer>
  );
};

export default ApplicationPage;
