
export enum OvenMediaLiveSessionStatus {
  INITIALIZED = 'initialized',
  RUNNING = 'running',
  TERMINATED = 'terminated',
  CANCEL = 'cancel',
}
export enum OvenMediaLiveSessionProtocol {
  WEBRTC = 'webrtc',
  RTMP = 'rtmp',
  SRT = 'srt',
  LLHLS = 'llhls',
}

export enum OvenMediaRecordSessionStatus {
  INITIALIZED = 'initialized',
  RECORDING = 'recording',
  ERROR_XML = 'error_xml',
  BUILDING_M3U8 = 'building_m3u8',
  ERROR_BUILD_M3U8 = 'error_build_m3u8',
  BUILDING_MP4 = 'building_mp4',
  ERROR_BUILD_MP4 = 'error_build_mp4',
  TERMINATED = 'terminated',
  CANCEL = 'cancel',
  EXPIRED = 'expired',
}

export enum OvenMediaRedirectionSessionStatus {
  INITIALIZED = 'initialized',
  RUNNING = 'running',
  TERMINATED = 'terminated',
  CANCEL = 'cancel',
}

export enum OvenMediaUploadProfileState {
  ENABLED = "enabled",
  DISABLED = "disabled",
}
export enum OvenMediaUploadProfileProtocole {
  SFTP = 'sftp',
}
export enum OvenMediaUploadProfileType {
  HLS = 'hls',
  MP4 = 'mp4',
}
export enum OvenMediaUploadSessionState {
  ONGOING = "ongoing",
  ERROR = "error",
  TERMINATED = "terminated",
}


export interface OvenMediaLiveSession {
  id: string,
  _server: string,
  status: OvenMediaLiveSessionStatus,
  protocol: OvenMediaLiveSessionProtocol,
  vhost: string,
  app: string,
  stream: string,
  url: string,
  newUrl: string,
  name?: string,
  clientIp?: string,
  clientPort?: number,
  initiatedAt: Date,
  startedAt?: Date,
  endedAt?: Date,
  data: string,
  records?: OvenMediaRecordSession[],
  redirections?: OvenMediaRedirectionSession[],
}

export interface OvenMediaRecordSession {
  id: string,
  _server: string,
  createdAt: Date,
  updatedAt: Date,
  vhost: string,
  app: string,
  stream: string,
  status: OvenMediaRecordSessionStatus,
  path: string,
  tracks: string[],
  startedAt?: Date,
  endedAt?: Date,
  expireAt?: string,
  deleted?: boolean,
  mp4?: string,
  data: string,
  uploadSessions?: OvenMediaUploadSession[];
  liveSession?: OvenMediaLiveSession;
}

export interface OvenMediaRedirectionSession {
  id: string;
  _server: string,
  vhost: string,
  app: string,
  stream: string,
  createdAt: Date,
  updatedAt: Date,
  status: OvenMediaRedirectionSessionStatus,
  redirection: string,
  redirectionKey: string,
  tracks: string[],
  startedAt?: Date,
  endedAt?: Date,
}

export interface OvenMediaUploadProfile {
  id: string;
  createdAt: Date,
  updatedAt: Date,
  state: OvenMediaUploadProfileState,
  protocole: OvenMediaUploadProfileProtocole,
  type: OvenMediaUploadProfileType,
  name?: string,
  host: string;
  port: string;
  path: string;
  username?: string;
}

export interface OvenMediaUploadSession {
  id: string;
  _server: string,
  createdAt: Date,
  updatedAt: Date,
  profile?: OvenMediaUploadProfile,
  state: OvenMediaUploadSessionState,
  type: OvenMediaUploadProfileType,
  localPath: string;
  remotePath: string;
  remoteFile?: string;
  error?: string;
}


export interface OvenMediaAppOutputProfileEncodeVideo {
  name?: string,
  bypass?: boolean,
  bitrate?: string,
  codec?: string,
  framerate?: number,
  height?: number,
  width?: number,
  profile?: 'baseline' | 'main' | 'hight',
  preset?: 'slower' | 'slow' | 'medium' | 'fast' | 'faster';
  keyFrameInterval?: number,
  bFrames?: number,
  threadCount?: number,
  bypassIfMatch?: {
    codec?: 'eq',
    width?: 'eq' | 'lte' | 'gte',
    height?: 'eq' | 'lte' | 'gte',
    sar?: 'eq'
  }
}

export interface OvenMediaAppOutputProfileEncodeAudio {
  name?: string,
  bypass?: boolean,
  bitrate?: string,
  codec?: string,
  channel?: number,
  samplerate?: number,
  bypassIfMatch?: {
    codec?: 'eq',
    samplerate?: 'eq' | 'lte' | 'gte',
    channel?: 'eq' | 'lte' | 'gte',
  }
}

export interface OvenMediaAppOutputProfileEncode {
  videos: OvenMediaAppOutputProfileEncodeVideo[],
  audios: OvenMediaAppOutputProfileEncodeAudio[],
}


export interface OvenMediaAppOutputProfilePlaylistRendition {
  name: string,
  audio?: string,
  video?: string,
}

export interface OvenMediaAppOutputProfilePlaylist {
  fileName: string,
  name: string,
  options?: {
    webRtcAutoAbr?: string,
  },
  renditions: OvenMediaAppOutputProfilePlaylistRendition[],
}


export interface OvenMediaAppOutputProfile {
  name: string,
  encodes: OvenMediaAppOutputProfileEncode,
  playlists: OvenMediaAppOutputProfilePlaylist[],
}

export interface OvenMediaTrackVideo {
  id: number,
  name: string,
  type: 'Video',
  video: {
    bypass: boolean,
    bitrate?: string,
    codec?: string,
    framerate?: number,
    height?: number,
    width?: number
  }
}

export interface OvenMediaTrackAudio {
  id: number,
  name: string,
  type: 'Audio',
  audio: {
    bypass: boolean,
    bitrate?: string,
    codec?: string,
    channel?: number,
    samplerate?: number,
  }
}

export interface OvenMediaStreamContext {
  vhost: string;
  app: string;
  stream: string;
  input?: {
    createdTime: string,
    sourceType: string,
    sourceUrl: string,
    tracks: (OvenMediaTrackVideo | OvenMediaTrackAudio)[]
  },
  outputs?: {
    name: string,
    tracks: (OvenMediaTrackVideo | OvenMediaTrackAudio)[]
  }[]
  live?: OvenMediaLiveSession;
  records?: OvenMediaRecordSession[];
  redirections?: OvenMediaRedirectionSession[];
}

export interface OvenMediaAppContext {
  vhost: string;
  app: string;
  outputProfiles?: OvenMediaAppOutputProfile[];
  streams: { [key: string]: OvenMediaStreamContext };
  providers?: OvenMediaAppProvider[];
  publishers?: OvenMediaAppPublisher[];
}

export interface OvenMediaVhostContext {
  vhost: string;
  apps: { [key: string]: OvenMediaAppContext };
}


export interface IServerData {
  loadBalancerScore?: number;
  expireAt: Date;
}
export interface IServer {
  _id: string;
  apiUrl: string;
  pullWebMediaUrl: string;
  pullWebMediaTlsUrl: string;
  data?: IServerData;
}

export interface OvenMediaContext {
  healthScore: number;
  server: IServer;
  vhosts: { [key: string]: OvenMediaVhostContext };
  uploadProfiles: OvenMediaUploadProfile[];
}

export enum OvenMediaAppProvider {
  UNKNOWN = "unknown",
  RTMP = "rtmp",
	RTSP = 		"rtsp",
	RTSP_PULL = 		"rtsp pull",
  OVT = "ovt",
	MPEG_TS = 		"mpeg-ts",	
	WEBRTC = 		"webrtc",
	SRT = 		"srt",
	FILE = 		"file",
	SCHEDULED = 		"scheduled",
	MULTIPLEX = 		"multiplex",
}

export enum OvenMediaAppPublisher {
  UNKNOWN = "unknown",
  WEBRTC = "webrtc",
	MPEGTSPUSH = 		"mpegtspush",
	RTMPPUSH = 		"rtmppush",
  SRTPUSH = "srtpush",
	PUSH = 		"push",	
	LLHLS = 		"llhls",
	OVT = 		"ovt",
	FILE = 		"file",
	THUMBNAIL = 		"thumbnail",
	HLS = 		"hls",
}