import React from 'react';
import DashboardNetworkStats from '../../../components/dashboard/network';
import { OvenMediaStreamStats } from '../../../interfaces/stats';
import { useOvenMediaStats } from '../../../store/context/hooks';



export interface PopupStatsNetworkStatsProps {
  serverId: string,
  mode: 'incoming' | 'outgoing',
  vhost: string,
  app: string,
  stream: string,
}


const PopupStatsNetworkStats = ({
  serverId,
  mode,
  vhost,
  app,
  stream,
}: PopupStatsNetworkStatsProps) => {
  const stats = useOvenMediaStats(serverId);
  const streamStats: OvenMediaStreamStats | undefined = stats?.vhosts[vhost]?.apps?.[app]?.streams[stream];
  if (!streamStats?.network) return null;
  return (
    <DashboardNetworkStats
      mode={mode}
      stats={streamStats.network[mode]}
      style={{minWidth: '300px'}}
    />
  );
};


export default PopupStatsNetworkStats;
