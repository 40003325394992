import React, { HTMLProps } from 'react';
import { ReactComponent as IconSearch } from '../../assets/icons/search.svg';
import styles from './SearchInput.module.css';


export interface SearchInputProps {
  iconClassName?: string;
  iconStyle?: React.CSSProperties;
  containerClassName?: string;
  containerStyle?: React.CSSProperties;
}

const SearchInput = (
  {
    iconClassName,
    iconStyle,
    containerClassName,
    containerStyle,
    ...props
  }: SearchInputProps & Partial<HTMLProps<HTMLInputElement>>,
) => {
  const classes = [styles.container];
  if (containerClassName) classes.push(containerClassName);
  const iconClasses = [styles.icon];
  if (iconClassName) iconClasses.push(iconClassName);
  return (
    <div className={classes.join(' ')} style={containerStyle}>
      <input className={styles.input} {...props} />
      <IconSearch className={iconClasses.join(' ')} width={18} />
    </div>
  )
};

export default SearchInput;
