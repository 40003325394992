import { io, Socket } from "socket.io-client";
import { generateRandomId } from "./utils";
import { EventEmitter } from 'events';
import { ApiErrorCode } from "../constants/apiError";

const OVENMEDIA_TIMEOUT_RESPONSE = 30000;

export enum OvenMediaSocketChannel {
  MESSAGE = 'message',
  ERROR = 'error',
}

export enum OvenMediaSocketCommandType {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS'
}

export enum OvenMediaSocketCommandAction {
  GET_CONTEXT = 'get_context',                // 🢃 Incomming
  SUBSCRIBE_CONTEXT = 'subscribe_context',    // 🢃 Incomming
  UNSUBSCRIBE_CONTEXT = 'unsubscribe_context',// 🢃 Incomming
  ON_CONTEXT = 'on_context',                  // 🢁 Outcoming

  GET_STATS = 'get_stats',                    // 🢃 Incomming
  SUBSCRIBE_STATS = 'subscribe_stats',        // 🢃 Incomming
  UNSUBSCRIBE_STATS = 'unsubscribe_stats',    // 🢃 Incomming
  ON_STATS = 'on_stats',                      // 🢁 Outcoming

  GET_OVENMEDIA_LOGS = 'get_ovenmedia_logs',  // 🢃 Incomming
  SUBSCRIBE_OVENMEDIA_LOGS = 'subscribe_ovenmedia_logs',        // 🢃 Incomming
  UNSUBSCRIBE_OVENMEDIA_LOGS = 'unsubscribe_ovenmedia_logs',    // 🢃 Incomming
  ON_OVENMEDIA_LOGS = 'on_ovenmedia_logs',                      // 🢁 Outcoming

  GET_SERVERS = 'get_servers',                // 🢃 Incomming
  SUBSCRIBE_SERVERS = 'subscribe_servers',    // 🢃 Incomming
  UNSUBSCRIBE_SERVERS = 'unsubscribe_servers',// 🢃 Incomming
  ON_SERVERS = 'on_servers',                  // 🢁 Outcoming

  STOP_STREAM = 'stop_stream',

  START_RECORD = 'start_record',
  STOP_RECORD = 'stop_record',
  UPLOAD_RECORD = 'upload_record',
  DELETE_RECORD = 'delete_record',

  ADD_REDIRECTION = 'add_redirection',
  DELETE_REDIRECTION = 'delete_redirection',

  GET_LIVE_SESSIONS = 'get_live_sessions',              // 🢃 Incomming

  GET_RECORD_SESSIONS = 'get_record_sessions',              // 🢃 Incomming

  GET_UPLOAD_PROFILES = 'get_upload_profiles',          // 🢃 Incomming
}

export enum OvenMediaSocketConnectionError {
  UNKNOW,
  NETWORK,
  UNAUTHORIZED,
  TOKEN_EXPIRED,
  SOCKET,
  SOCKET_UNAUTHORIZED,
}

export interface OvenMediaSocketErrorMessage {
  name: string;
  message: string;
  stack: string;
  code: ApiErrorCode;
  cname: string;
  timeout: boolean;
}

export interface OvenMediaSocketCommand<T = any> {
  correlationId: string,
  action: OvenMediaSocketCommandAction,
  content?: T,
}

export interface OvenMediaSocketResponseCommand<T = any> {
  correlationId: string,
  type: OvenMediaSocketCommandType,
  content?: T,
}

export interface OvenMediaSocketResponseError extends OvenMediaSocketResponseCommand<OvenMediaSocketErrorMessage> { }

export interface OvenMediaSocketResultEmittedCommand<REQUEST = any, RESPONSE = any> {
  command: OvenMediaSocketCommand<REQUEST>,
  error: OvenMediaSocketErrorMessage | undefined,
  response: RESPONSE | undefined,
}

export interface OvenMediaSocketEmitCommandOptions {
  forceCorrelationId?: string,
  timeout?: number,
}

export const OvenMediaSocketEventKill = 'kill';
export const OvenMediaSocketEventResponseMessage = 'response_message';


export class OvenMediaSocket {
  readonly uri: string;
  private logger?: (...args: any[]) => void;
  private socket?: Socket;
  private correlatedMessages: Map<string, OvenMediaSocketCommand> = new Map();
  private event = new EventEmitter();
  private active: boolean = false;
  private connected: boolean = false;
  public serverId?: string;

  onConnect?: () => void;
  onDisconnect?: () => void;
  onConnectionFailed?: (error: OvenMediaSocketConnectionError) => void;
  onErrorMessageCallback?: (message: OvenMediaSocketErrorMessage) => void;

  onCommand?: (command: OvenMediaSocketCommand) => void;
  onResponseCommand?: (command: OvenMediaSocketResponseCommand) => void;

  constructor(uri: string) {
    this.uri = uri;
    // this.logger = console.log;
  }

  public isConnected(): boolean {
    return this.connected;
  }

  private async getSocketSessionToken(url: string, header?: [string, string][], params?: { [key: string]: string }): Promise<string | undefined> {
    const requestInit: RequestInit = {
      headers: header,
    };
    const urlObj = new URL(url);
    if (params) {
      for (let key in params) {
        urlObj.searchParams.set(key, params[key]);
      }
    }
    let response: Response | undefined;
    let success = true;
    try {
      response = await fetch(urlObj.href, requestInit);
    } catch (err) {
      success = false;
      this.logger?.('Error catched during getting Socket session token : ', err);
      this.onConnectionFailed?.(OvenMediaSocketConnectionError.NETWORK);
    }

    if (!success || !response) return undefined;

    if (response.status === 460) {
      this.onConnectionFailed?.(OvenMediaSocketConnectionError.TOKEN_EXPIRED);
      this.logger?.('User token expired - error 460');
      throw new Error('User token expired - error 460');
    }

    if (response.status === 403) {
      this.onConnectionFailed?.(OvenMediaSocketConnectionError.UNAUTHORIZED);
      this.logger?.('User unauthorized - error 403');
      throw new Error('User unauthorized - error 403');
    }

    if (!response.ok) {
      this.logger?.('Error getting Socket session token, response is incorrect : ', response);
      this.onConnectionFailed?.(OvenMediaSocketConnectionError.NETWORK);
      return undefined;
    }

    let data: any;
    try {
      data = await response.json();
    } catch (err) {
      this.logger?.('Error catched during parse response : ', err);
      this.onConnectionFailed?.(OvenMediaSocketConnectionError.UNKNOW);
    }
    return data?.token;
  }

  async connect(userToken: string, enpointGenerateSocketSessionToken: string) {
    this.active = true;
    const socketSessionToken = await this.getSocketSessionToken(enpointGenerateSocketSessionToken, [['Authorization', 'Bearer ' + userToken]]);
    if (!socketSessionToken) return false;
    this.active = true;
    const socketUrl = new URL(this.uri);
    socketUrl.searchParams.set('token', socketSessionToken);
    this.socket = io(socketUrl.href, { reconnection: false, transports: ['websocket'] });
    if (!this.socket) return false;

    // Channel listeners :
    this.socket.on('connect', async () => {
      this.logger?.('Socket connected');
      this.connected = true;
      if (this.onConnect) this.onConnect();
    });

    this.socket.on('disconnect', async () => {
      this.logger?.('Socket disconnected');
      if (this.onDisconnect) this.onDisconnect();
    });

    this.socket.on('connect_error', async () => {
      this.logger?.('Connection Failed : SOCKET');
      if (this.onConnectionFailed) this.onConnectionFailed(OvenMediaSocketConnectionError.SOCKET);
    });

    this.socket.on(OvenMediaSocketChannel.MESSAGE, async (...args: any[]) => {
      if (args.length === 0) return;
      this.logger?.('Socket message : ', args[0]);
      this.receiveMessage(args[0]);
    });

    this.socket.on(OvenMediaSocketChannel.ERROR, async (...args: any[]) => {
      if (args.length === 0) return;
      this.logger?.('Socket ERROR message : ', args[0]);
      this.receiveErrorMessage(args[0]);
    });

    return true;
  }

  disconnect() {
    this.active = false;
    this.event.emit(OvenMediaSocketEventKill);
    this.socket?.disconnect();
  }

  private receiveMessage(data: any) {
    if ('action' in data) {
      this.event.emit(OvenMediaSocketEventResponseMessage, data);
      this.correlatedMessages.delete(data.correlationId);
      this.onCommand?.(data);
    } else if ('correlationId' in data) {
      this.event.emit(OvenMediaSocketEventResponseMessage, data);
      this.correlatedMessages.delete(data.correlationId);
      this.onResponseCommand?.(data);
    }
  }


  private receiveErrorMessage(data: OvenMediaSocketErrorMessage) {
    this.onErrorMessageCallback?.(data);
    if (data.code === -ApiErrorCode.SOCKET_AUTHENTICATION || data.code === -ApiErrorCode.SOCKET_UNAUTHORIZED) {
      this.logger?.('Connection socket unauthorized');
      this.onConnectionFailed?.(OvenMediaSocketConnectionError.SOCKET_UNAUTHORIZED);
    }
  }

  emit(on: string, ...args: any): void {
    if (!this.socket) return;
    this.socket.emit(on, ...args);
  }

  async emitCommand<REQUEST = any, RESPONSE = any>(
    action: OvenMediaSocketCommandAction,
    data?: REQUEST,
    options?: OvenMediaSocketEmitCommandOptions,
  ): Promise<OvenMediaSocketResultEmittedCommand | undefined> {
    if (!this.socket) return undefined;
    const command: OvenMediaSocketCommand = {
      correlationId: generateRandomId(32),
      action,
      content: data,
    };
    let promise = new Promise<OvenMediaSocketResultEmittedCommand<REQUEST, RESPONSE>>(async (resolve) => {
      let timeout: NodeJS.Timeout | undefined;
      const result: OvenMediaSocketResultEmittedCommand<REQUEST, RESPONSE> = {
        command,
        error: undefined,
        response: undefined,
      };
      if (!this.socket) {
        return result;
      }

      const listener = (data: OvenMediaSocketResponseCommand) => {
        if (data.correlationId !== command.correlationId) return;
        this.event.removeListener(OvenMediaSocketEventResponseMessage, listener);
        if (timeout) clearTimeout(timeout);
        if (data.type === OvenMediaSocketCommandType.SUCCESS) {
          result.response = data.content;
        } else {
          result.error = data.content;
        }
        resolve(result);
      };

      // Stop waiting response after timeout
      const responseTimeout = () => {
        result.error = {
          name: 'Command timeout',
          message: 'Server doesn\'t respond to this command.',
          stack: '',
          code: 1,
          cname: 'COMMAND_TIMEOUT',
          timeout: true,
        };
        resolve(result);
        this.event.removeListener(OvenMediaSocketEventResponseMessage, listener);
      }
      timeout = setTimeout(responseTimeout, (options?.timeout && options?.timeout > 0 ? options?.timeout : OVENMEDIA_TIMEOUT_RESPONSE));

      this.event.addListener(OvenMediaSocketEventResponseMessage, listener);

      // Emit Message
      this.emit(OvenMediaSocketChannel.MESSAGE, command);
    });
    return promise;
  }
}