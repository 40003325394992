import React, { useMemo } from 'react';
import { useOvenMediaAllStats, useOvenMediaContexts, useOvenMediaStats } from '../../../store/context/hooks';
import styles from './player.module.css';



export interface DashboardPlayerContainerProps {
  serverId?: string;
  className?: string;
  style?: React.CSSProperties;
}

const DashboardPlayerContainer = ({
  serverId,
  className,
  style,
}: DashboardPlayerContainerProps) => {
  const allStats = useOvenMediaAllStats();
  const contexts = useOvenMediaContexts();
  const connections = useMemo(() => {
    const result = {
      total: 0,
      hls: 0,
      llhls: 0,
      dash: 0,
      lldash: 0,
      ovt: 0,
      webrtc: 0,
    };
    const checkServer = (serverId: string) => {
      const context = contexts?.find((c) => c.server._id === serverId);
      if (!context) return;
      const stats = allStats?.[serverId];
      if (!stats) return;
      result.webrtc += stats?.stats?.connections?.webrtc ?? 0; 
      result.ovt += stats?.stats?.connections?.ovt ?? 0; 
      result.hls += stats?.stats?.connections?.hls ?? 0; 
      result.llhls += stats?.stats?.connections?.llhls ?? 0; 
      result.dash += stats?.stats?.connections?.dash ?? 0; 
      result.lldash += stats?.stats?.connections?.lldash ?? 0; 
    }
    if (serverId) {
      checkServer(serverId);
    } else {
      contexts?.forEach((c) => {
        checkServer(c.server._id);
      });
    }

    result.total = result.webrtc + result.ovt + result.hls + result.llhls + result.dash + result.lldash;
    return result;
  }, [serverId, allStats, contexts]);

  const classes = [styles.container];
  if (className) classes.push(className);
  return (
    <div className={classes.join(' ')} style={style}>
      <div className={styles.rowContainer} >
      <div className={styles.detailsContainer} style={{ backgroundColor: 'var(--color-main-green)'}}>
          <div className={styles.detailsTitle} style={{ color: 'white'}}>
            {'TOTAL' /* TRANSLATION */}
          </div>
          <div className={styles.detailsValue} style={{ color: 'white'}}>
            {connections?.total ?? 0}
          </div>
        </div>
      <div className={styles.detailsContainer}>
          <div className={styles.detailsTitle}>
            {'WEBRTC' /* TRANSLATION */}
          </div>
          <div className={styles.detailsValue}>
            {connections?.webrtc ?? 0}
          </div>
        </div>
      <div className={styles.detailsContainer}>
          <div className={styles.detailsTitle}>
            {'OVT' /* TRANSLATION */}
          </div>
          <div className={styles.detailsValue}>
            {connections?.ovt ?? 0}
          </div>
        </div>
        <div className={styles.detailsContainer}>
          <div className={styles.detailsTitle}>
            {'HLS' /* TRANSLATION */}
          </div>
          <div className={styles.detailsValue}>
            {connections?.hls ?? 0}
          </div>
        </div>
        <div className={styles.detailsContainer}>
          <div className={styles.detailsTitle}>
            {'LLHLS' /* TRANSLATION */}
          </div>
          <div className={styles.detailsValue}>
            {connections?.llhls ?? 0}
          </div>
        </div>
        <div className={styles.detailsContainer}>
          <div className={styles.detailsTitle}>
            {'DASH' /* TRANSLATION */}
          </div>
          <div className={styles.detailsValue}>
            {connections?.dash ?? 0}
          </div>
        </div>
        <div className={styles.detailsContainer}>
          <div className={styles.detailsTitle}>
            {'LLDASH' /* TRANSLATION */}
          </div>
          <div className={styles.detailsValue}>
            {connections?.lldash ?? 0}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPlayerContainer;
