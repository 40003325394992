import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSocketConnexion } from '../../hooks/socket';
import { AuthStatus } from '../../interfaces/session';
import { Loading } from '../utils/Loading';

interface PrivateRouteProps {
  redirectPath?: string,
  authStatus: AuthStatus,
}

const PrivateRoute = ({ redirectPath = '/', authStatus }: PrivateRouteProps) => {
  useSocketConnexion();
  if (authStatus === AuthStatus.Out) {
    const url = new URL(window.location.href);
    const currentPath = url.pathname.startsWith('/') ? url.pathname.replace('/', '') : url.pathname;
    let redirection = redirectPath!;
    if (currentPath.length && currentPath !== 'logout') {
      redirection += `?redirect=${currentPath}`;
    }
    return <Navigate to={redirection} replace />;
  }
  if (authStatus === AuthStatus.Loading) {
    return <Loading />;
  }
  return <Outlet />;
};

export default PrivateRoute;
