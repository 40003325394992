import { Touchable, useStateWithRef } from "@kalyzee/kast-app-web-components";
import { useEffect, useRef, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import PageContainer from "../../components/page/PageContainer";
import { delay } from "../../helpers/utils";
import { useSocketAppDispatch } from "../../hooks/app";
import { useReduxAction } from "../../hooks/store";
import { OvenMediaContext } from "../../interfaces/context";
import { ActionState } from "../../store/action";
import { contextOnLogs } from "../../store/context/actions";
import { useOvenMediaContexts } from "../../store/context/hooks";
import { useMultiServer } from "../../store/session/hooks";
import { socketGetLogs, socketSubscribeLogs, socketUnsubscribeLogs } from "../../store/socket/actions";
import styles from "./logs.module.css";

const LogsPage = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const socketDispatch = useSocketAppDispatch();
  const logsContainerRef = useRef<HTMLDivElement>(null);
  const logsRef = useRef<string>("En attente de logs ..." /* TRANSLATION */);
  const multiServer = useMultiServer();
  const contexts = useOvenMediaContexts();
  const [currContext, setCurrContext] = useStateWithRef<OvenMediaContext | undefined>();

  useEffect(() => {
    if (!multiServer) setCurrContext(contexts[0]);
  }, [contexts]);

  const initLogs = async (context: OvenMediaContext, controller: AbortController) => {
    logsRef.current = "";
    updateLogsContainerContent(true);
    await delay(500); // fix undefined result
    const result = await socketDispatch(socketGetLogs({ serverId: context.server._id }));
    if (result && !result.error && result.response) {
      logsRef.current = result?.response;
      setLoading(false);
      setTimeout(() => {
        if (controller.signal.aborted) return;
        updateLogsContainerContent(true);
        socketDispatch(socketSubscribeLogs({ serverId: context.server._id }));
      }, 100);
    }
  };

  const updateLogsContainerContent = (forceScrollToBottom: boolean = false) => {
    if (!logsContainerRef.current) return;
    const scrollTop = logsContainerRef.current.scrollTop;
    const height = logsContainerRef.current.offsetHeight;
    const contentHeight = logsContainerRef.current.scrollHeight;
    const scrollBottom = contentHeight - height - scrollTop;
    const margerToScrollToBottom = 50;
    logsContainerRef.current.textContent = logsRef.current;

    if (forceScrollToBottom || scrollBottom < margerToScrollToBottom) {
      logsContainerRef.current.scrollTo({
        top: logsContainerRef.current.scrollHeight,
        behavior: "auto",
      });
      setTimeout(() => {
        if (!logsContainerRef.current) return;
        logsContainerRef.current.scrollTo({
          top: logsContainerRef.current.scrollHeight,
          behavior: "auto",
        });
      }, 20);
    }
  };

  useReduxAction(
    (action: ActionState) => {
      const serverId = action?.payload?.serverId;
      const logs = action?.payload?.content;
      const append = action?.payload?.append;
      if (!logs || !serverId) return;
      if (currContext?.server?._id !== serverId) return;
      if (logsContainerRef.current) {
        if (append) {
          logsRef.current = (logsContainerRef.current.textContent ?? "") + logs;
        } else {
          logsRef.current = logs;
        }
        updateLogsContainerContent();
      }
    },
    contextOnLogs.type,
    []
  );

  useEffect(() => {
    if (!currContext) return;
    const controller = new AbortController();
    // SUBSCRIBE TO LOGS
    initLogs(currContext, controller);
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => {
      controller.abort();
      clearTimeout(timeout);
      // UNSUBSCRIBE TO LOGS
      socketDispatch(socketUnsubscribeLogs({ serverId: currContext.server._id }));
    };
  }, [currContext]);

  const renderContent = (context: OvenMediaContext) => {
    return (
      <>
        <div className={styles.logsContainer} ref={logsContainerRef}>
          {logsRef.current}
        </div>
        <Touchable
          className={styles.refreshButton}
          onPress={async () => {
            const result = await socketDispatch(socketGetLogs({ serverId: context.server._id }));
            if (!result.error) {
              logsRef.current = result?.response;
              updateLogsContainerContent(true);
            }
          }}
        >
          {"Recharger" /* TRANSLATION */}
        </Touchable>
      </>
    );
  };

  return (
    <PageContainer title={"Logs" /* TRANSLATION */} subtitle={""} loading={false}>
      {multiServer ? (
        <Tabs
          className={[styles.tabs].join(' ')}
          onSelect={(index) => {
            const context = index < contexts.length ? contexts[index] : undefined;
            setCurrContext(context);
          }}
        >
          <TabList>
            {contexts.map((c) => (
              <Tab  key={`logs_tab_${c.server._id}`}>{`Server ${c.server._id}`}</Tab>
            ))}
          </TabList>
          {contexts.map((c) => (
            <TabPanel className={[styles.tabsContent, "react-tabs-content"].join(' ')} key={`logs_content_${c.server._id}`}>{renderContent(c)}</TabPanel>
          ))}
        </Tabs>
      ) : (
        renderContent(contexts[0])
      )}
    </PageContainer>
  );
};

export default LogsPage;
