import {
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload,
  Middleware,
  MiddlewareAPI,
  PayloadAction
} from '@reduxjs/toolkit';
import { getBaseUrl, getUserSocketUrl } from '../../helpers/request';
import { OvenMediaSocket, OvenMediaSocketCommand, OvenMediaSocketCommandAction, OvenMediaSocketConnectionError, OvenMediaSocketResultEmittedCommand } from '../../helpers/socket';
import { getToken } from '../../helpers/storage';
import { toastError } from '../../helpers/toast';
import { addToPathNameUrl } from '../../helpers/utils';
import { SocketStatus } from '../../interfaces/socket';
import { contextOnLogs } from '../context/actions';
import { setContext, setStats } from '../context/slices';
import { logout } from '../session/slices';
import socketActions, { SocketAddRedirectionParams, SocketDeleteRecordParams, SocketDeleteRedirectionParams, SocketGetContextParams, SocketGetSessionParams, SocketStartRecordParams, SocketStopRecordParams, SocketStopStreamParams, SocketUploadRecordParams } from './actions';
import { setSocketStatus } from './slices';
import { getEnvMultiServer } from '../../helpers/env';

const log = (...args: string[]) => {
  // eslint-disable-next-line no-console
  console.log('[SOCKET] ', ...args);
};

type ServerId = string;
interface OvenMediaSockets { main: OvenMediaSocket, all: Map<ServerId, OvenMediaSocket>}
type PayloadForServerId<T = undefined> =  T extends undefined ? { serverId: ServerId} : { serverId: ServerId, data: T };
/// Redux Action Handlers ///

interface ActionEntryWithPayload<P = any> {
  actionCreator: ActionCreatorWithPayload<P>,
  handle: (
    sockets: OvenMediaSockets ,
    store: MiddlewareAPI,
    payload: P,
  ) => (Promise<OvenMediaSocketResultEmittedCommand | undefined> | void),
}

interface ActionEntry {
  actionCreator: ActionCreatorWithoutPayload,
  handle: (sockets: OvenMediaSockets , store: MiddlewareAPI) => void,
}

const reduxActionHandlers: (ActionEntry | ActionEntryWithPayload)[] = [
  {
    actionCreator: socketActions.socketConnect,
    handle: async (sockets: OvenMediaSockets, store: MiddlewareAPI) => {
      const socket = sockets.main;
      try {
        if (socket) socket.disconnect();
      } catch {}
      try {
        const userToken = getToken() || '';
        await socket.connect(userToken, addToPathNameUrl(getBaseUrl(), '/socket/token'));
      } catch (err) {
        toastError(`Error during connection websocket : ${err}`);
        store.dispatch(logout());
        store.dispatch(setSocketStatus(SocketStatus.Offline));
      }
      return undefined;
    },
  },
  {
    actionCreator: socketActions.socketDisconnect,
    handle: (sockets: OvenMediaSockets, store: MiddlewareAPI) => {
      const socket = sockets.main;
      socket.disconnect();
      return undefined;
    },
  },
  {
    actionCreator: socketActions.socketGetContext,
    handle: async (sockets: OvenMediaSockets, store: MiddlewareAPI, payload: PayloadForServerId<SocketGetContextParams>) => {
      const {serverId, data} = payload;
      const socket = sockets.all.get(serverId);
      if (!socket) {
        const message = `No socket connect with this serverId: ${serverId}`;
        toastError(message);
        throw new Error(message);
      }
      return await socket.emitCommand(OvenMediaSocketCommandAction.GET_CONTEXT, data);
    },
  },
  {
    actionCreator: socketActions.socketGetStats,
    handle: async (sockets: OvenMediaSockets, store: MiddlewareAPI, payload: PayloadForServerId) => {
      const {serverId } = payload;
      const socket = sockets.all.get(serverId);
      if (!socket) {
        const message = `No socket connect with this serverId: ${serverId}`;
        toastError(message);
        throw new Error(message);
      }
      return await socket.emitCommand(OvenMediaSocketCommandAction.GET_STATS);
    },
  },
  {
    actionCreator: socketActions.socketGetLiveSessions,
    handle: async (sockets: OvenMediaSockets, store: MiddlewareAPI, payload: SocketGetSessionParams) => {
      const socket = sockets.main;
      return await socket.emitCommand(OvenMediaSocketCommandAction.GET_LIVE_SESSIONS, payload);
    },
  },
  {
    actionCreator: socketActions.socketGetRecordSessions,
    handle: async (sockets: OvenMediaSockets, store: MiddlewareAPI, payload: SocketGetSessionParams) => {
      const socket = sockets.main;
      return await socket.emitCommand(OvenMediaSocketCommandAction.GET_RECORD_SESSIONS, payload);
    },
  },
  {
    actionCreator: socketActions.socketSubscribeStats,
    handle: async (sockets: OvenMediaSockets, store: MiddlewareAPI, payload: PayloadForServerId) => {
      const {serverId } = payload;
      const socket = sockets.all.get(serverId);
      if (!socket) {
        const message = `No socket connect with this serverId: ${serverId}`;
        toastError(message);
        throw new Error(message);
      }
      return await socket.emitCommand(OvenMediaSocketCommandAction.SUBSCRIBE_STATS);
    },
  },
  {
    actionCreator: socketActions.socketUnsubscribeStats,
    handle: async (sockets: OvenMediaSockets, store: MiddlewareAPI, payload: PayloadForServerId) => {
      const {serverId } = payload;
      const socket = sockets.all.get(serverId);
      if (!socket) {
        const message = `No socket connect with this serverId: ${serverId}`;
        toastError(message);
        throw new Error(message);
      }
      return await socket.emitCommand(OvenMediaSocketCommandAction.UNSUBSCRIBE_STATS);
    },
  },
  {
    actionCreator: socketActions.socketSubscribeLogs,
    handle: async (sockets: OvenMediaSockets, store: MiddlewareAPI, payload: PayloadForServerId) => {
      const {serverId } = payload;
      const socket = sockets.all.get(serverId);
      if (!socket) {
        const message = `No socket connect with this serverId: ${serverId}`;
        toastError(message);
        throw new Error(message);
      }
      return await socket.emitCommand(OvenMediaSocketCommandAction.SUBSCRIBE_OVENMEDIA_LOGS);
    },
  },
  {
    actionCreator: socketActions.socketUnsubscribeLogs,
    handle: async (sockets: OvenMediaSockets, store: MiddlewareAPI, payload: PayloadForServerId) => {
      const {serverId } = payload;
      const socket = sockets.all.get(serverId);
      if (!socket) {
        const message = `No socket connect with this serverId: ${serverId}`;
        toastError(message);
        throw new Error(message);
      }
      return await socket.emitCommand(OvenMediaSocketCommandAction.UNSUBSCRIBE_OVENMEDIA_LOGS);
    },
  },
  {
    actionCreator: socketActions.socketGetLogs,
    handle: async (sockets: OvenMediaSockets, store: MiddlewareAPI, payload: PayloadForServerId) => {
      const {serverId } = payload;
      const socket = sockets.all.get(serverId);
      if (!socket) {
        const message = `No socket connect with this serverId: ${serverId}`;
        toastError(message);
        throw new Error(message);
      }
      return await socket.emitCommand(OvenMediaSocketCommandAction.GET_OVENMEDIA_LOGS);
    },
  },
  {
    actionCreator: socketActions.socketStopStream,
    handle: async (sockets: OvenMediaSockets, store: MiddlewareAPI, payload: PayloadForServerId<SocketStopStreamParams>) => {
      const {serverId, data} = payload;
      const socket = sockets.all.get(serverId);
      if (!socket) {
        const message = `No socket connect with this serverId: ${serverId}`;
        toastError(message);
        throw new Error(message);
      }
      return await socket.emitCommand(OvenMediaSocketCommandAction.STOP_STREAM, data);
    },
  },
  {
    actionCreator: socketActions.socketStartRecord,
    handle: async (sockets: OvenMediaSockets, store: MiddlewareAPI, payload: PayloadForServerId<SocketStartRecordParams>) => {
      const {serverId, data} = payload;
      const socket = sockets.all.get(serverId);
      if (!socket) {
        const message = `No socket connect with this serverId: ${serverId}`;
        toastError(message);
        throw new Error(message);
      }
      return await socket.emitCommand(OvenMediaSocketCommandAction.START_RECORD, data);
    },
  },
  {
    actionCreator: socketActions.socketStopRecord,
    handle: async (sockets: OvenMediaSockets, store: MiddlewareAPI, payload: PayloadForServerId<SocketStopRecordParams>) => {
      const {serverId, data} = payload;
      const socket = sockets.all.get(serverId);
      if (!socket) {
        const message = `No socket connect with this serverId: ${serverId}`;
        toastError(message);
        throw new Error(message);
      }
      return await socket.emitCommand(OvenMediaSocketCommandAction.STOP_RECORD, data);
    },
  },
  {
    actionCreator: socketActions.socketDeleteRecord,
    handle: async (sockets: OvenMediaSockets, store: MiddlewareAPI, payload: PayloadForServerId<SocketDeleteRecordParams>) => {
      const {serverId, data} = payload;      const socket = sockets.all.get(serverId);
      if (!socket) {
        const message = `No socket connect with this serverId: ${serverId}`;
        toastError(message);
        throw new Error(message);
      }
      return await socket.emitCommand(OvenMediaSocketCommandAction.DELETE_RECORD, data);
    },
  },
  {
    actionCreator: socketActions.socketAddRedirection,
    handle: async (sockets: OvenMediaSockets, store: MiddlewareAPI, payload: PayloadForServerId<SocketAddRedirectionParams>) => {
      const {serverId, data} = payload;
      const socket = sockets.all.get(serverId);
      if (!socket) {
        const message = `No socket connect with this serverId: ${serverId}`;
        toastError(message);
        throw new Error(message);
      }
      return await socket.emitCommand(OvenMediaSocketCommandAction.ADD_REDIRECTION, data);
    },
  },
  {
    actionCreator: socketActions.socketUploadRecord,
    handle: async (sockets: OvenMediaSockets, store: MiddlewareAPI, payload: PayloadForServerId<SocketUploadRecordParams>) => {
      const {serverId, data} = payload;
      const socket = sockets.all.get(serverId);
      if (!socket) {
        const message = `No socket connect with this serverId: ${serverId}`;
        toastError(message);
        throw new Error(message);
      }
      return await socket.emitCommand(OvenMediaSocketCommandAction.UPLOAD_RECORD, data);
    },
  },
  {
    actionCreator: socketActions.socketDeleteRedirection,
    handle: async (sockets: OvenMediaSockets, store: MiddlewareAPI, payload: PayloadForServerId<SocketDeleteRedirectionParams>) => {
      const {serverId, data} = payload;
      const socket = sockets.all.get(serverId);
      if (!socket) {
        const message = `No socket connect with this serverId: ${serverId}`;
        toastError(message);
        throw new Error(message);
      }
      return await socket.emitCommand(OvenMediaSocketCommandAction.DELETE_REDIRECTION, data);
    },
  },
  
];

const reduxActionMatcher = (
  sockets: OvenMediaSockets ,
  store: MiddlewareAPI,
  action: PayloadAction,
) : Promise<OvenMediaSocketResultEmittedCommand | undefined> | undefined => {
  // eslint-disable-next-line no-restricted-syntax
  for (const handler of reduxActionHandlers) {
    if (handler.actionCreator.match(action)) {
      const result = handler.handle(sockets, store, action.payload);
      if (result instanceof Promise) return result;
      return undefined;
    }
  }
  return undefined;
};

/// Middleware ///

export const socketMiddleware: Middleware = (store) => {
  const multiServers = getEnvMultiServer();
  const mainSocket = new OvenMediaSocket(getUserSocketUrl());
  const sockets: OvenMediaSockets = {
    main: mainSocket,
    all: new Map(),
  };

  if (multiServers) {
    const manageSockets = (servers: any) => {
      const serverIdToRemove: string[] = [];
      Array.from(sockets.all.keys()).forEach((k) => {
        const socket = sockets.all.get(k);
        if (!socket) {
          serverIdToRemove.push(k);
          return;
        }
        const serverId = socket?.serverId;
        let remove = true;
        if (serverId && servers.find((s: any) => s._id === serverId)) remove = false;
        if (remove) serverIdToRemove.push(k); 
      });
      serverIdToRemove.forEach((s) => {
        const socket = sockets.all.get(s);
        sockets.all.delete(s);
        if (socket) socket.disconnect();
      })
      servers.forEach(async (s: any) => {
        const serverId = s._id;
        const apiUrl = s.apiUrl;
        if (!serverId) return;
        const currSocket = sockets.all.get(serverId);
        if (currSocket) return;

        const websocketEndpoint = getUserSocketUrl(apiUrl);
        console.log('Create socket : ', serverId, apiUrl, ' - ', websocketEndpoint)
        const newSocket = new OvenMediaSocket(websocketEndpoint);
        newSocket.serverId = serverId;

        const connect = async () => {
          let success = false;
          try {
            const userToken = getToken() || '';
            await newSocket.connect(userToken, addToPathNameUrl(apiUrl, '/socket/token'));
            success = true;
          } catch (err) {
            toastError(`Error during connection websocket (server: ${serverId}) : ${err}`);
          }
          return success;
        };
        const success = await connect();

        if (!success) return;
        sockets.all.set(serverId, newSocket);
        newSocket.onConnect = () => {
          log(`Connected ${serverId}`);
          newSocket.emitCommand(OvenMediaSocketCommandAction.GET_CONTEXT, { refresh: true }).then((result) => {
            if (result?.error) {
              toastError(`Code: ${result?.error.code}\nMessage: ${result?.error.message}`);
              return;
            }
            const context = result?.response;
            const serverId = context?.server?._id;
            if (serverId) {
              store.dispatch(setContext({ serverId: serverId, context: context}));
            }
          }).catch((error) => toastError(`Error get context`));
          newSocket.emitCommand(OvenMediaSocketCommandAction.GET_STATS).then((result) => {
            if (result?.error) {
              toastError(`Code: ${result?.error.code}\nMessage: ${result?.error.message}`);
              return;
            }
            store.dispatch(setStats({ serverId, stats: result?.response}));
          }).catch((error) => toastError(`Error get stats`));
          newSocket.emitCommand(OvenMediaSocketCommandAction.SUBSCRIBE_STATS);
          newSocket.emitCommand(OvenMediaSocketCommandAction.SUBSCRIBE_CONTEXT);
        };
      
        newSocket.onCommand = (command: OvenMediaSocketCommand) => {
          if(command.action === OvenMediaSocketCommandAction.ON_OVENMEDIA_LOGS) {
            const serverId = newSocket.serverId;
            if (serverId) {
              store.dispatch(contextOnLogs({
                serverId,
                content: command.content,
                append: true,
              }));
            }
          }
      
          if(command.action === OvenMediaSocketCommandAction.ON_CONTEXT) {
            const context = command.content;
            const serverId = context?.server?._id;
            if (serverId) {
              store.dispatch(setContext({ serverId: serverId, context: context}));
            }
          }
      
          if(command.action === OvenMediaSocketCommandAction.ON_STATS) {
            const stats = command.content;
            const serverId = newSocket.serverId;
            if (serverId) store.dispatch(setStats({ serverId: serverId, stats: stats}));
            
          }
        }  

        newSocket.onDisconnect = () => {
          if (!newSocket.serverId) return;
          log(`Disconnect ${serverId}`);
          const reconnect = sockets.all.get(newSocket.serverId) ? true : false;
          if (reconnect) connect();
        };
      
        newSocket.onConnectionFailed = (error: OvenMediaSocketConnectionError) => {
          if (!newSocket.serverId) return;
          log(`Connection failed ${serverId}`);
          const reconnect = sockets.all.get(newSocket.serverId) ? true : false;
          if (reconnect) connect();
        };
      });
    }
    
    mainSocket.onConnect = () => {
      log('Connected');
      store.dispatch(setSocketStatus(SocketStatus.Online));
      
      mainSocket.emitCommand(OvenMediaSocketCommandAction.GET_SERVERS, { refresh: true }).then((result) => {
        if (result?.error) {
          toastError(`Code: ${result?.error.code}\nMessage: ${result?.error.message}`);
          return;
        }
        const servers = result?.response?.servers;
        manageSockets(servers);
      }).catch((error) => toastError(`Error get servers`));
      mainSocket.emitCommand(OvenMediaSocketCommandAction.SUBSCRIBE_SERVERS);
    };
  
    mainSocket.onCommand = (command: OvenMediaSocketCommand) => {  
      if(command.action === OvenMediaSocketCommandAction.ON_SERVERS) {
        const servers = command.content?.servers;
        manageSockets(servers);
      }
    }
  
  } else {
    mainSocket.onConnect = () => {
      log('Connected');
      store.dispatch(setSocketStatus(SocketStatus.Online));
      mainSocket.emitCommand(OvenMediaSocketCommandAction.GET_CONTEXT, { refresh: true }).then((result) => {
        if (result?.error) {
          toastError(`Code: ${result?.error.code}\nMessage: ${result?.error.message}`);
          return;
        }
        const context = result?.response;
        const serverId = context?.server?._id;
        if (serverId) {
          mainSocket.serverId = serverId;
          sockets.all.set(serverId, mainSocket);
          store.dispatch(setContext({ serverId: serverId, context: context}));
        }
      }).catch((error) => toastError(`Error get context`));
      mainSocket.emitCommand(OvenMediaSocketCommandAction.GET_STATS).then((result) => {
        if (result?.error) {
          toastError(`Code: ${result?.error.code}\nMessage: ${result?.error.message}`);
          return;
        }
        if (mainSocket.serverId) store.dispatch(setStats({ serverId: mainSocket.serverId, stats: result?.response}));
      }).catch((error) => toastError(`Error get stats`));
      mainSocket.emitCommand(OvenMediaSocketCommandAction.SUBSCRIBE_STATS);
      mainSocket.emitCommand(OvenMediaSocketCommandAction.SUBSCRIBE_CONTEXT);
    };
  
    mainSocket.onCommand = (command: OvenMediaSocketCommand) => {
      if(command.action === OvenMediaSocketCommandAction.ON_OVENMEDIA_LOGS) {
        const serverId = mainSocket.serverId;
        if (serverId) {
          store.dispatch(contextOnLogs({
            serverId,
            content: command.content,
            append: true,
          }));
        }
      }
  
      if(command.action === OvenMediaSocketCommandAction.ON_CONTEXT) {
        const context = command.content;
        const serverId = context?.server?._id;
        if (serverId) {
          mainSocket.serverId = serverId;
          sockets.all.set(serverId, mainSocket);
          store.dispatch(setContext({ serverId: serverId, context: context}));
        }
      }
  
      if(command.action === OvenMediaSocketCommandAction.ON_STATS) {
        const stats = command.content;
        const serverId = mainSocket.serverId;
        if (serverId) store.dispatch(setStats({ serverId: serverId, stats: stats}));
        
      }
    }  
  }



  mainSocket.onDisconnect = () => {
    log('Disconnected');
    store.dispatch(setSocketStatus(SocketStatus.Loading));
  };

  mainSocket.onConnectionFailed = (error: OvenMediaSocketConnectionError) => {
    log('Connection Failed');
    store.dispatch(setSocketStatus(SocketStatus.Offline));
  };

  console.log('sockets: ', sockets)

  // eslint-disable-next-line arrow-body-style
  return (next) => async (action) => {
    const result = reduxActionMatcher(sockets, store, action);
    if (result) return result;
    return next(action);
  };
};

export default {
  socketMiddleware,
};
