import React, { useImperativeHandle, useRef } from "react";
import Table, { TableColumnType, TableConf, TableConfColumn, TableContentRef, TableStyle } from "../../../components/utils/Table";
import { useRender } from "../../../hooks/component";
import { useElementSize } from "../../../hooks/window";
import { OvenMediaAppOutputProfilePlaylistRendition } from "../../../interfaces/context";
import styles from "./table-renditions-encoder.module.css";

export interface TablePlaylistRenditionData extends OvenMediaAppOutputProfilePlaylistRendition {}

export interface TablePlaylistRenditionRef {
  render: () => void;
}

export interface TablePlaylistRenditionProps {
  data: TablePlaylistRenditionData[];
  onItemChecked?: (item: TablePlaylistRenditionData) => void;
  className?: string;
  style?: React.CSSProperties;
}
const TablePlaylistRendition = React.forwardRef(
  ({ data, onItemChecked, className, style }: TablePlaylistRenditionProps, forwardRef: React.ForwardedRef<TablePlaylistRenditionRef | undefined>) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const render = useRender();
    const size = useElementSize(containerRef);

    useImperativeHandle(forwardRef, () => ({
      render,
    }));

    const generateConfiguration = () => {
      const columnConfiguration: TableConfColumn<TablePlaylistRenditionData>[] = [
        /* {
        type: TableColumnType.CHECKBOX,
        key: 'checked',
      }, */
        {
          type: TableColumnType.CLASSIC,
          key: "name",
          width: "15%",
          minWidth: "7.5rem",
          title: "Name" /* TRANSLATION */,
          header: { className: styles.tableHeaderCellName },
          item: { className: styles.tableCellName },
        },
        {
          type: TableColumnType.CLASSIC,
          key: "audio",
          title: "Audio Encoder" /* TRANSLATION */,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "video",
          title: "Video Encoder" /* TRANSLATION */,
        },
      ];

      const tableConfiguration: TableConf<TablePlaylistRenditionData> = {
        columns: columnConfiguration,
        header: {
          className: styles.tableHeader,
          cell: {
            className: styles.tableHeaderCell,
          },
        },
        row: {
          className: styles.tableRow,
          cell: {
            className: styles.tableRowCell,
          },
        },
        content: { className: styles.tableContent },
        valueToShowIfUndefined: { value: "-", className: styles.tableUndefinedValue },
      };

      return tableConfiguration;
    };

    // Called when a value is changed. Checkboxes here
    const valueChanged = (value: any, columnKey: string, item: TablePlaylistRenditionData) => {
      if (columnKey === "checked") onItemChecked?.(item);
    };

    const customRenderCell = (element: JSX.Element | null, elementRef: TableContentRef, columnKey: string, item: TablePlaylistRenditionData) => {
      return element;
    };

    const addCustomStyleOnCell = (columnKey: string, item: TablePlaylistRenditionData) => {
      const result: TableStyle = {};
      return result;
    };

    const addCustomStyleOnRow = (item: TablePlaylistRenditionData, currData: TablePlaylistRenditionData[], index: number) => {
      const result: TableStyle = {};
      return result;
    };

    const onRenderTableStarts = () => {};

    const onRenderTableEnded = () => {};

    const renderTable = () => (
      <Table
        className={styles.table}
        data={data}
        keyExtractor={(_, item) => `key-${item.name}`}
        configuration={generateConfiguration()}
        onRenderCellRow={customRenderCell}
        onStyleCellRow={addCustomStyleOnCell}
        onStyleRow={addCustomStyleOnRow}
        onChangeValue={valueChanged}
        onRenderStarts={() => onRenderTableStarts}
        onRenderEnded={onRenderTableEnded}
      />
    );

    const classes = [styles.container];
    if (className) classes.push(className);
    return (
      <div className={classes.join(" ")} style={style} ref={containerRef}>
        {renderTable()}
      </div>
    );
  }
);

export default TablePlaylistRendition;
