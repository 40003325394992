import React, { useState } from "react";
import PageContainer from "../../components/page/PageContainer";
import DashboardNetworkContainer from "./containers/network";
import DashboardPlayerContainer from "./containers/player";
import DashboardStreamContainer from "./containers/stream";
import DashboardSystemContainer from "./containers/system";

import styles from "./dashboard.module.css";
import { useOvenMediaContexts } from "../../store/context/hooks";
import { useMultiServer } from "../../store/session/hooks";
import { Touchable } from "@kalyzee/kast-app-web-components";

const DashboardPage = () => {
  const contexts = useOvenMediaContexts();
  const multiServer = useMultiServer();

  const [showSystemsDetails, setShowSystemsDetails] = useState(false);
  const [showActiveStreamDetails, setShowActiveStreamDetails] = useState(false);
  const [showPlayersDetails, setShowPlayersDetails] = useState(false);
  const [showNetworkDetails, setShowNetworkDetails] = useState(false);

  return (
    <PageContainer title={"Dashboard" /* TRANSLATION */} subtitle={""} loading={false}>
      <div style={{ width: "100%" }}>
        <div className={styles.sectionTitle}>{"STREAMS:" /* TRANSLATION */}</div>
        <DashboardStreamContainer style={{ marginBottom: "10px" }} />
        {multiServer ? (
          <div className={styles.detailsContainer}>
            <Touchable className={styles.detailsSeparator} onPress={() => setShowActiveStreamDetails(!showActiveStreamDetails)}>
              <div style={{ height: "1px", flex: 1, backgroundColor: "var(--color-main-indian-khaki)" }} />
              <div className={styles.detailsSeparatorTitle}>{showActiveStreamDetails ? `Hide details ${"▲"}` : `Show details ${"▼"}`}</div>
              <div style={{ height: "1px", flex: 1, backgroundColor: "var(--color-main-indian-khaki)" }} />
            </Touchable>
            {showActiveStreamDetails ? (
              contexts.map((c) => {
                const serverId = c.server._id;
                if (!serverId) return null;
                return (
                  <div key={`dashbord_stream_details_${serverId}`}>
                    <div className={styles.detailsServer}>{`Server: ${serverId}`}</div>
                    <DashboardStreamContainer serverId={serverId} style={{ marginBottom: "10px" }} />
                  </div>
                );
              })
            ) : null}
          </div>
        ) : null}
        <div className={styles.sectionTitle}>{"SYSTEMS:" /* TRANSLATION */}</div>
        <DashboardSystemContainer style={{ marginBottom: "10px" }} />
        {multiServer ? (
          <div className={styles.detailsContainer}>
            <Touchable className={styles.detailsSeparator} onPress={() => setShowSystemsDetails(!showSystemsDetails)}>
              <div style={{ height: "1px", flex: 1, backgroundColor: "var(--color-main-indian-khaki)" }} />
              <div className={styles.detailsSeparatorTitle}>{showSystemsDetails ? `Hide details ${"▲"}` : `Show details ${"▼"}`}</div>
              <div style={{ height: "1px", flex: 1, backgroundColor: "var(--color-main-indian-khaki)" }} />
            </Touchable>
            {showSystemsDetails ? (
              contexts.map((c) => {
                const serverId = c.server._id;
                if (!serverId) return null;
                return (
                  <div key={`dashbord_system_details_${serverId}`}>
                    <div className={styles.detailsServer}>{`Server: ${serverId}`}</div>
                    <DashboardSystemContainer key={`dashboard_system_${c.server._id}`} style={{ marginBottom: "10px" }} serverId={c.server._id} />
                  </div>
                );
              })
            ) : null}
          </div>
        ) : null}
        <div className={styles.sectionTitle}>{"PLAYERS:" /* TRANSLATION */}</div>
        <DashboardPlayerContainer style={{ marginBottom: "10px" }} />
        {multiServer ? (
          <div className={styles.detailsContainer}>
            <Touchable className={styles.detailsSeparator} onPress={() => setShowPlayersDetails(!showPlayersDetails)}>
              <div style={{ height: "1px", flex: 1, backgroundColor: "var(--color-main-indian-khaki)" }} />
              <div className={styles.detailsSeparatorTitle}>{showPlayersDetails ? `Hide details ${"▲"}` : `Show details ${"▼"}`}</div>
              <div style={{ height: "1px", flex: 1, backgroundColor: "var(--color-main-indian-khaki)" }} />
            </Touchable>
            {showPlayersDetails ? (
              contexts.map((c) => {
                const serverId = c.server._id;
                if (!serverId) return null;
                return (
                  <div key={`dashbord_player_details_${serverId}`}>
                    <div className={styles.detailsServer}>{`Server: ${serverId}`}</div>
                    <DashboardPlayerContainer serverId={serverId} style={{ marginBottom: "10px" }} />
                  </div>
                );
              })
            ) : null}
          </div>
        ) : null}
        <div className={styles.sectionTitle}>{"NETWORKS:" /* TRANSLATION */}</div>
        <DashboardNetworkContainer style={{ marginBottom: "10px" }} />
        {multiServer ? (
          <div className={styles.detailsContainer}>
            <Touchable className={styles.detailsSeparator} onPress={() => setShowNetworkDetails(!showNetworkDetails)}>
              <div style={{ height: "1px", flex: 1, backgroundColor: "var(--color-main-indian-khaki)" }} />
              <div className={styles.detailsSeparatorTitle}>{showNetworkDetails ? `Hide details ${"▲"}` : `Show details ${"▼"}`}</div>
              <div style={{ height: "1px", flex: 1, backgroundColor: "var(--color-main-indian-khaki)" }} />
            </Touchable>
            {showNetworkDetails ? (
              contexts.map((c) => {
                const serverId = c.server._id;
                if (!serverId) return null;
                return (
                  <div key={`dashbord_network_details_${serverId}`}>
                    <div className={styles.detailsServer}>{`Server: ${serverId}`}</div>
                    <DashboardNetworkContainer serverId={serverId} style={{ marginBottom: "10px" }} />
                  </div>
                );
              })
            ) : null}
          </div>
        ) : null}
      </div>
    </PageContainer>
  );
};

export default DashboardPage;
