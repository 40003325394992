import { OverlayHoverMessage, PopupButtonType, showPopup, TableSortDirection, Touchable } from "@kalyzee/kast-app-web-components";
import React, { createRef, useImperativeHandle, useRef } from "react";
import { ReactComponent as IconUploadProfile } from "../../assets/icons/upload-profile.svg";
import { ReactComponent as IconId } from "../../assets/icons/id.svg";
import Colors from "../../constants/colors";
import { useRender } from "../../hooks/component";
import { OvenMediaUploadSession, OvenMediaUploadSessionState } from "../../interfaces/context";
import Table, { TableColumnType, TableConf, TableConfColumn, TableContentRef, TableStyle } from "../utils/Table";
import styles from "./table-upload.module.css";

export interface TableUploadData extends OvenMediaUploadSession {}

export interface TableUploadRef {
  render: () => void;
}

export interface TableUploadProps {
  data: TableUploadData[];
  sort?: { field: string; direction: -1 | 1 };
  onSort?: (field: string, direction: -1 | 1) => void;
  onItemChecked?: (item: TableUploadData) => void;
  className?: string;
  style?: React.CSSProperties;
}
const TableUpload = React.forwardRef(
  ({ sort, onSort, data, onItemChecked, className, style }: TableUploadProps, forwardRef: React.ForwardedRef<TableUploadRef | undefined>) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const render = useRender();

    useImperativeHandle(forwardRef, () => ({
      render,
    }));

    const generateConfiguration = () => {
      const columnConfiguration: TableConfColumn<TableUploadData>[] = [
        {
          type: TableColumnType.CLASSIC,
          key: "id",
          width: "35px",
          title: "Id" /* TRANSLATION */,
          header: { className: styles.tableHeaderCellName },
          item: { className: styles.tableCellName },
        },
        {
          type: TableColumnType.CLASSIC,
          key: "createdAt",
          minWidth: "15rem",
          title: "Créé" /* TRANSLATION */,
          header: { className: styles.tableHeaderCellName },
          item: { className: styles.tableCellName },
          enableSort: true,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "updatedAt",
          width: "15rem",
          title: "Mis à jour" /* TRANSLATION */,
          enableSort: true,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "state",
          width: "7rem",
          title: "State" /* TRANSLATION */,
          enableSort: true,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "type",
          width: "7rem",
          title: "Type" /* TRANSLATION */,
          enableSort: true,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "localPath",
          width: "15rem",
          title: "Local path" /* TRANSLATION */,
          enableSort: true,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "remotePath",
          width: "15rem",
          title: "Remote path" /* TRANSLATION */,
          enableSort: true,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "remoteFile",
          title: "Remote file" /* TRANSLATION */,
          width: "7rem",
          enableSort: true,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "error",
          title: "Erreur" /* TRANSLATION */,
          width: "4rem",
          enableSort: true,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "profile",
          title: "Uploader" /* TRANSLATION */,
          width: "5rem",
          enableSort: true,
        },
      ];

      const defaultSort = sort ?? { field: "startedAt", direction: -1 };
      columnConfiguration.forEach((c) => {
        if (c.key === defaultSort.field) {
          c.defaultSort = defaultSort.direction === 1 ? TableSortDirection.ASC : TableSortDirection.DESC;
        }
      });

      const tableConfiguration: TableConf<TableUploadData> = {
        columns: columnConfiguration,
        header: {
          className: styles.tableHeader,
          cell: {
            className: styles.tableHeaderCell,
          },
        },
        row: {
          className: styles.tableRow,
          cell: {
            className: styles.tableRowCell,
          },
        },
        content: { className: styles.tableContent },
        valueToShowIfUndefined: { value: "-", className: styles.tableUndefinedValue },
      };

      return tableConfiguration;
    };

    // Called when a value is changed. Checkboxes here
    const valueChanged = (value: any, columnKey: string, item: TableUploadData) => {
      if (columnKey === "checked") onItemChecked?.(item);
    };

    const customRenderCell = (element: JSX.Element | null, elementRef: TableContentRef, columnKey: string, item: TableUploadData) => {
      if (columnKey === "id") {
        const ref = createRef<HTMLDivElement>();
        const id = item?.id ?? "";
        return (
          <div ref={ref} className={styles.name}>
            <Touchable
              className={styles.textOverflow}
              onPress={() => {
                showPopup({
                  content: (
                    <>
                      <div style={{ marginBottom: 10, fontWeight: "bold" }}>{"Id:" /* TRANSLATION */}</div>
                      <div>{id}</div>
                    </>
                  ),
                  buttons: [{ type: PopupButtonType.OK, element: "OK" }],
                });
              }}
            >
              <IconId width={17} height={17} />
            </Touchable>
            <OverlayHoverMessage targetRef={ref} icon={<div />} message={id} />
          </div>
        );
      }
      if (columnKey == "state") {
        const color = item.state === OvenMediaUploadSessionState.ERROR ? Colors.getTorchRed() : Colors.getMainGreen();
        return <div style={{ color }}>{item.state}</div>;
      }
      if (columnKey === "localPath") {
        const ref = createRef<HTMLDivElement>();
        const localPath = item.localPath ?? "-";
        return (
          <div ref={ref} className={styles.host}>
            <Touchable
              className={styles.textOverflow}
              onPress={() => {
                showPopup({
                  content: (
                    <>
                      <div style={{ marginBottom: 10, fontWeight: "bold" }}>{"Local Path:" /* TRANSLATION */}</div>
                      <div>{localPath}</div>
                    </>
                  ),
                  buttons: [{ type: PopupButtonType.OK, element: "OK" }],
                });
              }}
            >
              {localPath}
            </Touchable>
            <OverlayHoverMessage targetRef={ref} icon={<div />} message={localPath} />
          </div>
        );
      }
      if (columnKey === "remotePath") {
        const ref = createRef<HTMLDivElement>();
        const remotePath = item.remotePath ?? "-";
        return (
          <div ref={ref} className={styles.host}>
            <Touchable
              className={styles.textOverflow}
              onPress={() => {
                showPopup({
                  content: (
                    <>
                      <div style={{ marginBottom: 10, fontWeight: "bold" }}>{"Remote Path:" /* TRANSLATION */}</div>
                      <div>{remotePath}</div>
                    </>
                  ),
                  buttons: [{ type: PopupButtonType.OK, element: "OK" }],
                });
              }}
            >
              {remotePath}
            </Touchable>
            <OverlayHoverMessage targetRef={ref} icon={<div />} message={remotePath} />
          </div>
        );
      }
      if (columnKey === "error") {
        const ref = createRef<HTMLDivElement>();
        const error = item.error;
        return (
          <div ref={ref} className={styles.path}>
            <Touchable
              className={styles.textOverflow}
              onPress={() => {
                showPopup({
                  content: (
                    <>
                      <div style={{ marginBottom: 10, fontWeight: "bold" }}>{"Error:" /* TRANSLATION */}</div>
                      <div>{error}</div>
                    </>
                  ),
                  buttons: [{ type: PopupButtonType.OK, element: "OK" }],
                });
              }}
            >
              <div style={{ color: error ? Colors.getTorchRed() : Colors.getMainGreen() }}>{error ? "true" : "false"}</div>
            </Touchable>
            {error ? <OverlayHoverMessage targetRef={ref} icon={<div />} message={error} /> : null}
          </div>
        );
      }
      if (columnKey === "profile") {
        if (!item.profile) return "-";
        return (
          <Touchable
            className={styles.actionPlay}
            onPress={() => {
              showPopup({
                content: (
                  <div>
                    <div className={styles.popupTitle}>Profile:</div>
                    {JSON.stringify(item.profile, null, 4)}
                  </div>
                ),
                buttons: [{ type: PopupButtonType.OK, element: "OK" }],
              });
            }}
          >
            <IconUploadProfile width={20} height={20} />
          </Touchable>
        );
      }
      return element;
    };

    const addCustomStyleOnCell = (columnKey: string, item: TableUploadData) => {
      const result: TableStyle = {};
      return result;
    };

    const addCustomStyleOnRow = (item: TableUploadData, currData: TableUploadData[], index: number) => {
      const result: TableStyle = {};
      return result;
    };

    const onRenderTableStarts = () => {};

    const onRenderTableEnded = () => {};

    const transformValue = (columnKey: string, item: TableUploadData, initialValue: any, data: TableUploadData[], index: number) => {
      if (columnKey == "createdAt") {
        if (!item.createdAt) return undefined;
        const createdAt = new Date(item.createdAt);
        return `${createdAt.toLocaleDateString()} ${createdAt.toLocaleTimeString()}`;
      }
      if (columnKey == "updatedAt") {
        if (!item.updatedAt) return undefined;
        const updatedAt = new Date(item.updatedAt);
        return `${updatedAt.toLocaleDateString()} ${updatedAt.toLocaleTimeString()}`;
      }
      return initialValue;
    };

    const _onSort = (columnKey: string, direction: TableSortDirection | undefined) => {
      let numberDirection: 0 | 1 | -1 = 0;
      if (direction === TableSortDirection.ASC) numberDirection = 1;
      else if (direction === TableSortDirection.DESC) numberDirection = -1;
      if (numberDirection !== 0) onSort?.(columnKey, numberDirection);
    };

    const renderTable = () => (
      <Table
        className={styles.table}
        data={data}
        keyExtractor={(_, item) => `key-${item.id}`}
        configuration={generateConfiguration()}
        onRenderCellRow={customRenderCell}
        onStyleCellRow={addCustomStyleOnCell}
        onStyleRow={addCustomStyleOnRow}
        onChangeValue={valueChanged}
        onRenderStarts={() => onRenderTableStarts}
        onRenderEnded={onRenderTableEnded}
        transformValue={transformValue}
        onSort={_onSort}
      />
    );

    const classes = [styles.container];
    if (className) classes.push(className);
    return (
      <div className={classes.join(" ")} style={style} ref={containerRef}>
        {renderTable()}
      </div>
    );
  }
);

export default TableUpload;
