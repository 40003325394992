import React, { useMemo } from "react";
import DashboardNetworkStats from "../../../components/dashboard/network";
import { useOvenMediaAllStats, useOvenMediaContexts } from "../../../store/context/hooks";
import styles from "./network.module.css";

export interface ApplicationNetworkContainerProps {
  vhost: string;
  app: string;
  serverId?: string;
  className?: string;
  style?: React.CSSProperties;
}

const ApplicationNetworkContainer = ({ serverId, vhost, app, className, style }: ApplicationNetworkContainerProps) => {
  const allStats = useOvenMediaAllStats();
  const contexts = useOvenMediaContexts();
  const network = useMemo(() => {
    const network = {
      incoming: {
        current: 0, // Mbits/s
        min: 0, // Mbits/s
        max: 0, // Mbits/s
        total: 0, // Mbits
      },
      outgoing: {
        current: 0, // Mbits/s
        min: 0, // Mbits/s
        max: 0, // Mbits/s
        total: 0, // Mbits
      },
    };

    const checkServer = (serverId: string) => {
      const context = contexts?.find((c) => c.server._id === serverId);
      if (!context) return;
      const stats = allStats?.[serverId];
      const incoming = stats?.vhosts?.[vhost]?.apps?.[app]?.network?.incoming;
      const outgoing = stats?.vhosts?.[vhost]?.apps?.[app]?.network?.outgoing;
      if (!incoming || !outgoing) return;
      network.incoming.current += incoming.current;
      network.incoming.min = Math.min(network.incoming.min, incoming.min);
      network.incoming.max = Math.max(network.incoming.max, incoming.max);
      network.incoming.total += incoming.total;

      network.outgoing.current += outgoing.current;
      network.outgoing.min = Math.min(network.outgoing.min, outgoing.min);
      network.outgoing.max = Math.max(network.outgoing.max, outgoing.max);
      network.outgoing.total += outgoing.total;
    };
    if (serverId) {
      checkServer(serverId);
    } else {
      contexts?.forEach((c) => {
        checkServer(c.server._id);
      });
    }
    return network;
  }, [serverId, allStats, contexts]);

  const classes = [styles.container];
  if (className) classes.push(className);
  return (
    <div className={classes.join(" ")} style={style}>
      <div className={styles.sectionTitle}>NETWORK:</div>
      <div className={styles.cardsContainer}>
        <DashboardNetworkStats mode="incoming" stats={network?.incoming} className={styles.detailsContainer} />
        <DashboardNetworkStats mode="outgoing" stats={network?.outgoing} className={styles.detailsContainer} />
      </div>
    </div>
  );
};

export default ApplicationNetworkContainer;
