import { useAppSelector } from '../../hooks/app';
import { OvenMediaContext, OvenMediaUploadProfile } from '../../interfaces/context';
import { OvenMediaStats } from '../../interfaces/stats';
import { selectContexts, selectStats } from './selectors';

export const useOvenMediaContexts = () : OvenMediaContext[] => {
  const obj = useAppSelector(selectContexts);
  return Object.values(obj);
};
export const useOvenMediaServerIds = () : string[] => {
  const obj = useAppSelector(selectContexts);
  return obj ? Object.keys(obj) : [];
};
export const useOvenMediaUploadProfiles = () : OvenMediaUploadProfile[] => {
  const contexts = useOvenMediaContexts();
  return contexts.length ? contexts[0].uploadProfiles : [];
};
export const useOvenMediaAllStats = () : {[serverId: string]: OvenMediaStats} | undefined => useAppSelector(selectStats);
export const useOvenMediaStats = (serverId: string) : OvenMediaStats | undefined => {
  const allStats = useOvenMediaAllStats();
  return allStats?.[serverId];
};

export default {
  useOvenMediaServerIds,
  useOvenMediaContexts,
  useOvenMediaAllStats,
  useOvenMediaStats,
};
