import React, { useMemo } from "react";
import { ReactComponent as IconLiveStream } from "../../../assets/icons/stream.svg";
import { VHOST } from "../../../constants/ovenmedia";
import { useOvenMediaAllStats, useOvenMediaContexts } from "../../../store/context/hooks";
import styles from "./stream.module.css";
import { getNbOfStream } from "../../../helpers/streams";

export interface DashboardStreamContainerProps {
  serverId?: string;
  className?: string;
  style?: React.CSSProperties;
}

const DashboardStreamContainer = ({ serverId, className, style }: DashboardStreamContainerProps) => {
  const contexts = useOvenMediaContexts();
  const streams = useMemo(() => {
    return getNbOfStream(contexts, serverId);
  }, [serverId, contexts]);

  const classes = [styles.container];
  if (className) classes.push(className);
  return (
    <div className={classes.join(" ")} style={style}>
      <div className={styles.detailsContainer}>
        <IconLiveStream width={20} height={20} />
        <div className={styles.detailsTitle}>{"Active live streams:" /* TRANSLATION */}</div>
        <div className={styles.detailsValue}>{streams}</div>
      </div>
    </div>
  );
};

export default DashboardStreamContainer;
