import React from 'react';
import { ReactComponent as IconIncoming } from '../../assets/icons/network-incoming.svg';
import { ReactComponent as IconOutgoing } from '../../assets/icons/network-outgoing.svg';
import Colors from '../../constants/colors';
import { OvenMediaNetworkTrafficStats } from '../../interfaces/stats';
import styles from './network.module.css';



export interface DashboardNetworkStatsProps {
  mode: 'incoming' | 'outgoing';
  stats?: Omit<OvenMediaNetworkTrafficStats, 'average' | 'date'>;
  className?: string;
  style?: React.CSSProperties;
}


const DashboardNetworkStats = ({
  mode,
  stats,
  className,
  style,
}: DashboardNetworkStatsProps) => {
  const formatDebit = (value: number, fixed: number = 2) => `${value?.toFixed(fixed)} Mbits/s`;
  const classes = [styles.detailsContainer];
  if (className) classes.push(className);

  const networkStats = stats;
  const titles = {
    incoming: 'Incoming', /* TRANSLATION */
    outgoing: 'Outgoing', /* TRANSLATION */
  };
  const colors = {
    incoming: Colors.getMainGreen(),
    outgoing: Colors.getTreePoppy()
  };
  const icons = {
    incoming: <IconIncoming width={25} height={25} />,
    outgoing: <IconOutgoing width={25} height={25} />
  };
  return (
    <div className={classes.join(' ')} style={style}>
      <div className={styles.headerDetailsContainer}>
        {icons[mode]}
        <div className={styles.detailsTitle}>
          {titles[mode]}
        </div>
      </div>
      <div className={styles.rowDetailsContainer}>
        <div className={styles.detailsTitle}>{'Current' /* TRANSLATION */}</div>
        <div className={styles.detailsValue} style={{ fontSize: 22, fontWeight: 'bold', color: colors [mode] }}> {formatDebit(networkStats?.current ?? 0, 3)} </div>
      </div>
      <div className={styles.rowDetailsContainer}>
        <div className={styles.detailsTitle}>{'Min' /* TRANSLATION */}</div>
        <div className={styles.detailsValue}> {formatDebit(networkStats?.min ?? 0)} </div>
      </div>
      <div className={styles.rowDetailsContainer}>
        <div className={styles.detailsTitle}>{'Max' /* TRANSLATION */}</div>
        <div className={styles.detailsValue}> {formatDebit(networkStats?.max ?? 0)} </div>
      </div>
      <div className={styles.rowDetailsContainer}>
        <div className={styles.detailsTitle}>{'Total' /* TRANSLATION */}</div>
        <div className={styles.detailsValue}> {`${((networkStats?.total ?? 0) / 1000000)?.toFixed(2)} Mbits`} </div>
      </div>
    </div>
  )
};

export default DashboardNetworkStats;
