import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { ReactComponent as IconVideo } from "../../assets/icons/video.svg";
import styles from "./rtc-video-stats.module.css";
import { usePrevious } from "../../hooks/utils";
import Colors from "../../constants/colors";

export interface RtcVideoStatsProps {
  stats?: any;
  className?: string;
  style?: React.CSSProperties;
}

export interface RtcVideoStatsRef {
  update: (stats: any) => void;
}

const RtcVideoStats = React.forwardRef(
  (
    { stats, className, style }: RtcVideoStatsProps,
    forwardRef: React.ForwardedRef<RtcVideoStatsRef | undefined>
  ) => {
    const [currStats, setCurrStats] = useState<{stats: any}>({ stats });
    const lastStats = usePrevious(currStats);
    const formatDebit = (value: number, fixed: number = 2) =>
      `${value?.toFixed(fixed)} Mbits/s`;
    const classes = [styles.detailsContainer];
    if (className) classes.push(className);

    useImperativeHandle(forwardRef, () => ({
      update: (s: any) => setCurrStats({ stats: s }),
    }));

    useEffect(() => {
      setCurrStats({ stats });
    }, [stats]);

    const data: { title: string; value: string, valueStyle?: any }[] = [];

    if (currStats.stats) {
      const arrayLastStats: any[] = lastStats?.stats ? Array.from(lastStats.stats?.values()) : [];
      const lastInboundStats = arrayLastStats.find((s) => s.type === 'inbound-rtp');

      const arrayStats: any[] = Array.from(currStats.stats?.values());
      const inboundStats = arrayStats.find((s) => s.type === 'inbound-rtp');

      if (inboundStats?.bytesReceived !== undefined && lastInboundStats?.bytesReceived  !== undefined) {
        const diffBytes = (inboundStats.bytesReceived - lastInboundStats.bytesReceived) / 1000000; // Mb
        const diffDate = (inboundStats.timestamp - lastInboundStats.timestamp) / 1000; // s
        if (diffDate > 0) {
          data.push({
            title: 'Bitrate', /* TRANSLATION */
            value: formatDebit(8 * diffBytes / diffDate, 3),
            valueStyle:{ fontSize: 22, fontWeight: 'bold', color: Colors.getMainGreen() }
          })
        }
      }

      if (inboundStats?.frameHeight !== undefined) {
        data.push({
          title: 'Height', /* TRANSLATION */
          value: inboundStats.frameHeight,
        })
      }
      if (inboundStats?.frameWidth !== undefined) {
        data.push({
          title: 'Width', /* TRANSLATION */
          value: inboundStats.frameWidth,
        })
      }
      if (inboundStats?.framesPerSecond !== undefined) {
        data.push({
          title: 'FPS', /* TRANSLATION */
          value: inboundStats.framesPerSecond,
        })
      }
      
      if (inboundStats?.jitter !== undefined) {
        data.push({
          title: 'Jitter', /* TRANSLATION */
          value: inboundStats.jitter,
        })
      }
      if (inboundStats?.packetsLost !== undefined) {
        data.push({
          title: 'Packets lost', /* TRANSLATION */
          value: inboundStats.packetsLost,
        })
      }
      if (inboundStats?.packetsReceived !== undefined) {
        data.push({
          title: 'Packets received', /* TRANSLATION */
          value: inboundStats.packetsReceived,
        })
      }
      if (inboundStats?.packetsLost !== undefined && inboundStats?.packetsReceived !== undefined) {
        data.push({
          title: 'Packets lost %', /* TRANSLATION */
          value: `${(100 * inboundStats.packetsLost / inboundStats.packetsReceived).toFixed(2)}%`,
        })
      }
    }
    return (
      <div className={classes.join(" ")} style={style}>
        <div className={styles.headerDetailsContainer}>
          <IconVideo width={25} height={25} />
          <div className={styles.detailsTitle}>{"Video" /* TRANSLATION */}</div>
        </div>
        {data.map((d) => (
          <div className={styles.rowDetailsContainer} key={d.title}>
            <div className={styles.detailsTitle}>{d.title}</div>
            <div className={styles.detailsValue} style={d.valueStyle ?? {}}> {d.value} </div>
          </div>
        ))}
      </div>
    );
  }
);

export default RtcVideoStats;
